import { AvailabilityLevel } from '../../algolia.types'

/* eslint-disable max-len */
export default `
    <div v-if="statefulVariants.length" class="product-list" :class="{ loading: loading }">
        <article v-for="variant in statefulVariants" class="product-card" :data-variant-id="variant.id">
            <div class="product-card--content">

                <div class="wishlist-toggle">
                    <button v-if="variant.isFavorite" type="button" class="wishlist-toggle--button" @click="removeFavorite(variant)">
                        <svg class="svg-heart">
                            <use xlink:href="#svg-heart"></use>
                        </svg>
                    </button>
                    <button v-else type="button" class="wishlist-toggle--button" @click="addFavorite(variant)">
                        <svg class="svg-heart-outline">
                            <use xlink:href="#svg-heart-outline"></use>
                        </svg>
                    </button>
                </div>

                <a tabindex="-1"
                   :href="variant.url"
                   class="product-card--image-container"
                   @click="productWasClicked(variant)"
                >
                    <span class="product-card--image-overlay">
                        <img class="main-image"
                             :alt="variant.product_title"
                             :src="variant.main_image"
                             loading="lazy"
                        >
                        <img class="aux-image"
                             :alt="variant.product_title"
                             :src="variant.aux_image"
                             v-if="variant.aux_image"
                             loading="lazy"
                        >
                    </span>
                    <span class="product-card--discount">
                        <span v-if="variant.is_discounted" class="product-discount-badge">
                          -{{variant.discount_percentage}}%
                        </span>
                    </span>
                    <span class="product-card--badges">
                        <div v-if="variant.product_badge"
                             class="product-badge"
                             :style="backgroundImage(variant.product_badge)"
                        >
                        </div>
                    </span>
                </a>

                <a class="product-card--title" :href="variant.url" @click="productWasClicked(variant)">
                    <div v-if="variant.is_basiclabel_exclusive" class="product-card--exclusive">
                        Exclusief Basiclabel
                    </div>
                    {{variant.product_title}} {{variant.title}}
                </a>

                <div class="product-card--rating-container">
                    <img :src="'/images/ratings/rating-' + Math.round(variant.rating) + '.svg'" alt="Hoe geliefd is dit product?" />
                </div>

                <div class="product-card--price-container">
                    <div v-if="variant.is_discounted" class="price-display">
                        <div class="price-display--strike-through">{{formatPrice(variant.price)}}</div>
                        <div class="price-display--sale">{{formatPrice(variant.discount_price)}}</div>
                    </div>
                    <div v-else class="price-display">
                        <div class="price-display--default">
                            {{formatPrice(variant.price)}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-card--flyout-container">
                <div class="product-card--flyout-south">
                    <div class="product-card--delivery-info">
                        <div v-html="variant.availability_info.html"></div>
                        <div v-html="variant.deliveryPromise"></div>
                    </div>

                    <div class="product-card--actions">
                        <div class="sold-out-tooltip plp hide">
                            <div class="progress-bar">
                                <div class="progress-bar--indicator"></div>
                            </div>
                            <p>Helaas het product is (tijdelijk) niet meer leverbaar</p>
                        </div>

                        <button type="button"
                                :class="'button green svg-button medium ' + (variant.availability_level >= ${AvailabilityLevel.AVAILABLE_OVER_TIME} ? '' : 'hide')"
                                @click="addBuyableToCart(variant, variant.unit_quantity)"
                        >
                            <span>Bestel nu!</span>
                        </button>

                        <button :class="'button medium dark svg-button ' + (variant.availability_level === ${AvailabilityLevel.TEMPORARILY_UNAVAILABLE} ? '' : 'hide')"
                                @click="requestNotifyMeModal(variant.product_slug, variant.slug)"
                        >
                            <span>Geef me een seintje</span>
                            <svg class="svg-mail-icon">
                                <use xlink:href="#svg-mail-icon"></use>
                            </svg>
                        </button>

                        <div v-if="! (variant.availability_level >= ${AvailabilityLevel.AVAILABLE_OVER_TIME}) && ! (variant.availability_level === ${AvailabilityLevel.TEMPORARILY_UNAVAILABLE})"
                            class="sold-out">
                            <span>Bestel nu!</span>
                        </div>
                        </div>
                    </div>
                </div>

        </article>
    </div>

    <div v-else class="product-list-empty">
        <h2>Geen resultaten</h2>
        <p>Er zijn geen resultaten gevonden voor je huidige filterinstellingen.</p>
        <img class="shopping-cart--empty--image" src="/images/basiclabel-box.png" alt="Icoon van een Basiclabel doos" />
    </div>
`
