import { AjaxFormHandler, HttpClient } from '@app-services'

export class AjaxFormFactory {

    constructor(private readonly httpClient: HttpClient) {
    }

    public initialize(): void {
        const formElements = document.querySelectorAll<HTMLFormElement>('form[data-ajax]')

        formElements?.forEach((formElement: HTMLFormElement) => {
            this.createInstance(formElement)
        })
    }

    public createInstance(
        formElement: HTMLFormElement,
        recaptchaContainerId?: string,
    ): AjaxFormHandler {
        // Pass the reCAPTCHA container ID to the AjaxFormHandler constructor
        // If no ID is provided, it defaults to undefined, effectively disabling reCAPTCHA
        return new AjaxFormHandler(formElement, this.httpClient, recaptchaContainerId)
    }
}
