/**
 * Class to manage the filter sidebar toggle functionality.
 * This class is responsible for handling the opening and closing of the filter sidebar
 */
export class FilterSidebarToggle {
    private readonly filterSidebar: HTMLElement
    private readonly openFiltersButton: HTMLElement
    private readonly backdrop: HTMLElement
    private readonly closeButton: HTMLElement

    constructor() {
        this.filterSidebar = document.querySelector('.filter-sidebar') as HTMLElement
        this.openFiltersButton = document.querySelector('.plt--filter-toggle') as HTMLElement
        this.backdrop = document.querySelector('.filter-sidebar--backdrop') as HTMLElement
        this.closeButton = document.querySelector('.filter-sidebar--mobile--close') as HTMLElement

        this.initializeEvents()
    }

    private initializeEvents(): void {
        // List of elements that can open or close the filter sidebar
        const elements = [this.openFiltersButton, this.backdrop, this.closeButton]

        elements.forEach((element) => {
            element?.addEventListener('click', () => {
                this.filterSidebar.classList.toggle('active')
            })
        })
    }
}
