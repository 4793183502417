import Hammer from 'hammerjs'
import EventDelegation, { EventHandler } from '@jjwesterkamp/event-delegation'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { endpoints } from '@app-config/backend.config'
import type { IMutationReport } from './shopping-list.types'

const activeTimeouts = {
    successNotification: 5000,
    warningNotification: 8000,
    errorNotification: 8000,
}

export class ShoppingListNotificationWrapper {
    protected baseOffset: number = 0
    protected hideStartTimeout: number | null = null
    protected hideEndTimeout: number | null = null
    protected buttonCloseClickListener: EventHandler<HTMLElement>
    protected crossCloseClickListener: EventHandler<HTMLElement>
    protected enabled: boolean = true

    public constructor(
        protected readonly element: HTMLElement,
        protected readonly activeClass: string = 'active',
        protected readonly transitionDuration: number = 200,
    ) {
        this.makeSwipeable()

        this.crossCloseClickListener = EventDelegation
            .within(element)
            .events('click')
            .select('.close-button')
            .listen(() => {
                this.clearTimeouts()
                this.hide()
            })

        this.buttonCloseClickListener = EventDelegation
            .within(element)
            .events('click')
            .select('.continue__button')
            .listen(() => {
                this.clearTimeouts()
                this.hide()
            })
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled
    }

    public async handleReport(report: IMutationReport): Promise<void> {
        if (! this.enabled) {
            return
        }

        this.show(await this.fetchContent(report))
        this.clearTimeouts()

        const delay = report.attemptedQuantity() === report.currentQuantity()
            ? activeTimeouts.successNotification
            : activeTimeouts.warningNotification

        this.hideStartTimeout = window.setTimeout(() => this.hide(), delay)
    }

    protected async fetchContent(report: IMutationReport): Promise<string> {
        const response = await axios.get(endpoints.cart.notification, {
            params: report.toJSONSerializable(),
        })

        return response.data
    }

    protected hide(): void {
        this.element.classList.remove(this.activeClass)
        this.element.removeAttribute('role')

        this.hideEndTimeout = window.setTimeout(() => {
            this.element.innerHTML = ''
            this.element.style.transform = ''
        }, this.transitionDuration)

        this.resetBaseOffset()
    }

    protected show(content: string): void {
        this.element.innerHTML = content
        this.element.style.transform = ''
        this.element.setAttribute('role', 'alert')
        this.element.classList.add(this.activeClass)
    }

    protected clearTimeouts(): void {
        clearTimeout(this.hideStartTimeout!)
        clearTimeout(this.hideEndTimeout!)
    }

    protected makeSwipeable(): void {
        const hammerManager = new Hammer(this.element)
        const swipeRecognizer = new Hammer.Swipe()
        const panRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 })

        swipeRecognizer.recognizeWith(panRecognizer)

        hammerManager.add(swipeRecognizer)
        hammerManager.add(panRecognizer)

        hammerManager.on('panstart', () => this.startDrag())
        hammerManager.on('panmove', (e) => this.transformElement(this.baseOffset + e.deltaX))
        hammerManager.on('panend', (e) => this.endDrag(e.deltaX))
        hammerManager.on('swipe', () => this.handleSwipe())
    }

    protected handleSwipe(): void {
        this.resetBaseOffset()
        this.clearTimeouts()
        this.hide()
    }

    protected endDrag(deltaX: number): void {
        this.appendToBaseOffset(deltaX)
        this.element.classList.remove('dragging')
    }

    protected startDrag(): void {
        this.element.classList.add('dragging')
    }

    protected transformElement(offset: number): void {
        this.element.style.transform = `translateX(${offset}px)`
    }

    protected appendToBaseOffset(delta: number): void {
        this.baseOffset += delta
    }

    protected resetBaseOffset(): void {
        this.baseOffset = 0
    }
}
