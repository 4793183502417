export class AjaxError extends Error {
    status: number
    body?: any

    constructor(message: string, status: number, body?: any) {
        super(message)
        this.name = 'AjaxError'
        this.status = status
        this.body = body
    }
}
