// ------------------------------------------------------------------------------
//      Enums mirroring back-end
// ------------------------------------------------------------------------------

// noinspection JSUnusedGlobalSymbols
export enum PaymentMethod {
    CREDITCARD = 'payment-method--creditcard',
    IDEAL = 'payment-method--ideal',
    PAYPAL = 'payment-method--paypal',
    REIMBURSEMENT = 'payment-method--reimbursement',
    REMOTE = 'payment-method--remote',
    PIN = 'payment-method--pin',
    CASH = 'payment-method--cash',
    AFTERPAY = 'payment-method--afterpay',
    BANCONTACT = 'payment-method--bancontact',
    IN3 = 'payment-method--in3',
}

export enum TransferType {
    DELIVERY = 'transfer-type--delivery',
    PICKUP = 'transfer-type--pickup',
    DIGITAL = 'transfer-type--digital',
}

export enum AuthFlow {
    GUEST = 'checkout-auth-flow--guest',
    LOGIN = 'checkout-auth-flow--login',
    REGISTER = 'checkout-auth-flow--register',
}

// ------------------------------------------------------------------------------
//      Metadata
// ------------------------------------------------------------------------------

export interface PaymentFee {
    identifier: PaymentMethod
    /**
     * The payment fee as a float, in euros.
     */
    fee: number
}

// ------------------------------------------------------------------------------
//      Address fields & lookups
// ------------------------------------------------------------------------------

export interface AddressLookup {
    postal_code: string
    house_number: string
}

export interface AddressLookupResult {
    city: string
    postal_code: string
    province: string
    street: string
}

/**
 * Named map of input names used in an address-fields collection.
 */
export enum AddressInputName {
    HOUSE_NUMBER = 'house_number',
    HOUSE_NUMBER_ADDITION = 'house_number_addition',
    POSTAL_CODE = 'postal_code',
    COUNTRY_CODE = 'country_code',
    STREET_NAME = 'street_name',
    CITY = 'city',
}

export type AddressInputMode = 'auto' | 'manual'

export type AddressField = HTMLInputElement | HTMLSelectElement

// ------------------------------------------------------------------------------
//      Checkout steps UI
// ------------------------------------------------------------------------------

export enum StepBulletState {
    ACTIVE = 'active',
    FAILED = 'failed',
    COMPLETED = 'completed',
    WAITING = 'waiting',
}
