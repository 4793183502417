import { AddressLookupService } from '@app-services'

export class AddressLookupFactory {

    public initialize(): void {
        const formElementsWithAddressLookup = document.querySelectorAll<HTMLFormElement>('[data-address-lookup]')

        formElementsWithAddressLookup?.forEach((formElement: HTMLFormElement) => {
            this.createInstance(formElement)
        })
    }

    public createInstance(formElement: HTMLFormElement): AddressLookupService {
        return new AddressLookupService(formElement)
    }
}
