import { prop } from 'ramda'
import { connectMenu } from 'instantsearch.js/es/connectors'
import { RadioGroup } from '@app-components/radio-group/radio-group.component'
import { AvailabilityDateLimit, FacetAttribute, LocalFacetType } from '../../algolia.types'

export interface AvailabilityLimitWidgetOptions {
    container: HTMLElement,
    localFacetType: LocalFacetType,
    remoteFacetType: FacetAttribute,
}

export function createAvailabilityLimitWidget(options: AvailabilityLimitWidgetOptions) {

    const radioGroup = RadioGroup.createByInputName<AvailabilityDateLimit | ''>(`filter[${options.localFacetType}]`, {
        scope: options.container,
    })

    return connectMenu(({ refine, items }, isFirstRender) => {
        if (isFirstRender) {
            radioGroup.values$.subscribe((value) => refine(value || ''))
        } else {
            const newValue = (<AvailabilityDateLimit | undefined>items.find(prop('isRefined'))?.value) ?? ''
            radioGroup.checkByValue(newValue, false)
        }
    })({
        attribute: options.remoteFacetType,
    })
}
