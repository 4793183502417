import { SwiperModule } from 'swiper/types/shared'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { Autoplay, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper/modules'
import { BasicSwiper } from '@app-components'

export class SwiperFactory {

    public initialize(selector: string = '.basic-swiper'): void {
        const elements = document.querySelectorAll<HTMLElement>(selector)

        elements.forEach((element: HTMLElement) => {
            const options = this.getSwiperOptions(element)
            if (options.thumbs?.swiper) {
                this.createInstanceWithThumbnails(element, options)
            } else {
                this.createInstance(element, options)
            }
        })
    }

    /**
     * Creates a BasicSwiper instance with the provided options or options from the element's data attribute.
     * @param element - The HTML element to initialize Swiper on.
     * @param options - Swiper options to override the default options.
     * @param extraSwiperModules - Additional Swiper modules to load (optional)
     * @returns A new instance of BasicSwiper.
     */
    public createInstance(
        element: HTMLElement,
        options: SwiperOptions,
        extraSwiperModules: SwiperModule[] = [],
    ): BasicSwiper {
        return new BasicSwiper(element, {
            ...options,
            modules: [Scrollbar, Navigation, Pagination, Autoplay, ...extraSwiperModules],
        })
    }

    /**
     * Creates a BasicSwiper instance with thumbnail functionality.
     * This method is used when the swiper instance requires a thumbnail navigation.
     * @param element - The HTML element to initialize Swiper on.
     * @param options - Swiper options to override the default options.
     * @returns A new instance of BasicSwiper with thumbnail functionality.
     */
    public createInstanceWithThumbnails(element: HTMLElement, options: SwiperOptions): BasicSwiper {
        this.setupThumbnails(options)

        return new BasicSwiper(element, {
            ...options,
            modules: [Scrollbar, Thumbs, Navigation, Pagination],
        })
    }

    /**
     * Sets up the thumbnail Swiper instance if the options contain thumbnail configuration.
     * This is a helper method to initialize the thumbnail swiper instance and link it with the main swiper instance.
     * @param options - Swiper options that may include thumbnail configuration.
     */
    private setupThumbnails(options: SwiperOptions): void {
        if (options.thumbs?.swiper) {
            const thumbsElement = document.querySelector(options.thumbs.swiper.el)
            if (thumbsElement) {
                new BasicSwiper(thumbsElement as HTMLElement, {
                    ...options.thumbs.swiper,
                    modules: [Thumbs, Scrollbar],
                })
            }
        }
    }

    private getSwiperOptions(element: HTMLElement, providedOptions: SwiperOptions | null = null): SwiperOptions {
        const dataOptions = JSON.parse(element.dataset.swiperOptions || '{}')
        return { ...dataOptions, ...providedOptions }
    }
}
