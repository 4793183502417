import { fromEvent, merge, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { openWindow } from '@app-services/popups/popups.service'

/**
 * Handle clicks / enter-while-focus, on accept terms links. Opens a popup window over the current page.
 * @param {HTMLAnchorElement} trigger
 */
const start = (trigger: HTMLAnchorElement): Subscription => {

    // The window name is static. This will ensure that if a window with this name
    // already exists when the link is clicked, that window is refreshed instead
    // of opening another window.

    const windowName = 'Basiclabel-terms-and-conditions'

    const clicks$ = fromEvent(trigger, 'click')
    const enters$ = fromEvent(trigger, 'keyup').pipe(
        filter((event: KeyboardEvent) => event.key === '13'),
    )

    return merge(clicks$, enters$).subscribe((e) => {
        e.preventDefault()
        openWindow(trigger.href, windowName, 1080, 720)
    })
}

export const AcceptTerms = { start }
