import { inc, isEmpty, toPairs, transpose, type } from 'ramda'

export const colors = {
    black: 'black',
    indigo: '#516afd',
    teal: '#1ba28a',
    pink: '#924961',
    red: '#b94f52',
    orange: '#ae8204',
    green: '#25ac6a',
    gray: '#8d8d8d',
    blue: '#3c97e1',
    lime: '#92af00',
    limeDark: '#7c9000',
    chromeString: '#ff9e4f',
}

export function uncolored(content: string): [string, string] {
    return [
        `%c${content}`,
        '',
    ]
}

export function colored(content: string, color: string): [string, string] {
    return [
        `%c${content}`,
        `color: ${color};`,
    ]
}

export function badge(name: string, bg: string = 'transparent', otherCss: string = ''): string[] {
    return [
        `%c ${name} `,
        `background-color: ${bg}; color: #202020; border-radius: 2px; ${otherCss}`,
    ]
}

export function coloredSequence(segments: [string, string][]): string[] {
    const [values, sequenceColors] = transpose(segments)
    return [values.join(' '), ...sequenceColors]
}

/**
 * Recursively logs an object using console groups.
 */
export function expandedLog(item: any, maxDepth = 100, depth = 0): void {

    if (depth > maxDepth || typeof item !== 'object' || item === null) {
        if (typeof item === 'string') {
            console.log(...colored(`"${item}"`, colors.chromeString))
        } else {
            console.log(item)
        }
        return
    }

    if (isEmpty(Object.keys(item))) {
        console.log('<empty object>')
    }

    for (const [key, value] of toPairs(item as any)) {

        console.group(...coloredSequence([
            uncolored(key),
            colored('::', colors.red),
            uncolored(type(value)),
        ]))

        expandedLog(value, maxDepth, inc(depth))
        console.groupEnd()
    }
}
