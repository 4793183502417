import { fromEvent } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

/**
 * A passive stream of scroll events.
 */
export const scrolls$ = fromEvent(window, 'scroll', { passive: true })

export const getPageOffset = (): number => window.pageYOffset || document.documentElement.scrollTop

/**
 * Stream emitting the current page offset on scroll changes. This serves as source stream for page updates
 * that are dependent on scroll position / section intersection ratios with the window viewport.
 */
export const pageOffsets$ = scrolls$.pipe(
    map(getPageOffset),
    startWith(getPageOffset()),
)
