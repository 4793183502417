import { all, allPass, pipe, prop, values } from 'ramda'
import { isFiniteNumber, isPlainObject } from 'instantsearch.js/es/lib/utils'

import { AvailabilityDateLimit, LocalFacetType } from '../algolia.types'

/**
 * A raw numeric range object as parsed from the URL.
 */
export interface RawNumericRange {
    min: string
    max: string
}

/**
 * A parsed numeric range object as required for Algolia route states.
 */
export interface ParsedNumericRange {
    min: number
    max: number
}

/**
 * An array of string representations of model IDs; the type of an array
 * query that was parsed from the URL.
 */
export type SerializedIdList = string[]

/**
 * An array of model IDs as numbers.
 */
export type ParsedIdList = string[]

/**
 * The data format of a raw filter URL query. Has an index signature because this
 * is the entire query-string, that obviously can contain anything.
 */
export interface RawRouteState {
    [key: string]: any

    page?: string
    sorting?: string
    filter?: Partial<Record<LocalFacetType, SerializedIdList | RawNumericRange>>
}

/**
 * A parsed URL query, where ID values and price min/max values are parsed to
 * numbers, and any additional URL data besides that relevant to instantsearch
 * is omitted.
 */
export interface ParsedRouteState {
    page?: number
    sorting?: string
    filter?: Partial<Record<LocalFacetType, ParsedIdList | ParsedNumericRange | 'true'>>
}

/**
 * Parses a decimal integer from a string.
 */
export const parseDecimal = (x: string): number => parseInt(x, 10)

/**
 * Validates given argument, returning a boolean that indicates whether the argument is a valid list of IDs
 * represented as strings.
 */
export const isSerializedIdList = (x: any): x is SerializedIdList => allPass([
    Array.isArray,
    all(pipe(parseDecimal, isFiniteNumber)),
])(x)

/**
 * Validates given argument, returning a boolean that indicates whether the argument is a valid
 * {@link RawNumericRange raw numeric range}.
 */
export const isRawNumericRange = (x: any): x is RawNumericRange => allPass([
    isPlainObject,
    pipe(prop('min'), parseDecimal, isFiniteNumber),
    pipe(prop('max'), parseDecimal, isFiniteNumber),
])(x)

/**
 * Parses the given raw numeric range into an object where the min and max properties are parsed
 * to numbers.
 */
export const parseNumericRange = ({ min, max }: RawNumericRange): ParsedNumericRange => ({
    min: parseDecimal(min),
    max: parseDecimal(max),
})

/**
 * Parses the strings in a {@link SerializedIdList serialized-id list} to numbers.
 */
export const parseIdList = (ids: SerializedIdList): ParsedIdList => ids

/**
 * Parses a filter value as encountered in a {@link RawRouteState raw route state} object.
 */
export function parseFilterValue(x: SerializedIdList): ParsedIdList
export function parseFilterValue(x: RawNumericRange): ParsedNumericRange
export function parseFilterValue(x: AvailabilityDateLimit): AvailabilityDateLimit
export function parseFilterValue(x: any): undefined
export function parseFilterValue(x: any): any {
    if (values(AvailabilityDateLimit).includes(x)) {
        return x
    }

    if (isSerializedIdList(x)) {
        return parseIdList(x)
    }

    if (isRawNumericRange(x)) {
        return parseNumericRange(x)
    }

    return x
}
