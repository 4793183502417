import { fromEvent, Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { queryOneWithin } from 'lambda-dom'
import { parseDecimal } from '../../algolia/lib/route-state.lib'
import type { IQuantityControl, IQuantityControlConfiguration } from './quantity-control.types'

export class QuantityControl<Form extends HTMLFormElement = HTMLFormElement> implements IQuantityControl<Form> {

    public readonly values$: Observable<number>

    public readonly input: HTMLInputElement & { form: Form | null }
    public readonly stepUpButton: HTMLButtonElement
    public readonly stepDownButton: HTMLButtonElement

    public readonly minValue: number
    public readonly maxValue: number
    public readonly step: number

    constructor(protected config: IQuantityControlConfiguration) {
        const queryChild = queryOneWithin(config.container)

        this.input = queryChild<HTMLInputElement & { form: Form | null }>(config.numberInputSelector)!
        this.stepUpButton = queryChild<HTMLButtonElement>(config.stepUpButtonSelector)!
        this.stepDownButton = queryChild<HTMLButtonElement>(config.stepDownButtonSelector)!

        this.expectValidElements()

        // Gracefully defaults to an immutable range 0 - 0,
        // with step 1 if required attributes are not found.

        this.minValue = parseDecimal(this.input.getAttribute('min') || '0')
        this.maxValue = parseDecimal(this.input.getAttribute('max') || '0')
        this.step = parseDecimal(this.input.getAttribute('step') || '1')

        this.values$ = this.createValueStream()
    }

    public getForm(): Form | null {
        return this.input.form
    }

    public getValue(): number {
        return parseDecimal(this.input.value)
    }

    public setValue(value: number): boolean {
        if (value < this.minValue || value > this.maxValue) {
            return false
        }

        this.input.value = value.toString()
        this.refreshButtonStates()

        return true
    }

    protected createValueStream(): Observable<number> {

        const stepDownRequests = fromEvent(this.stepDownButton, 'click')
        const StepUpRequests = fromEvent(this.stepUpButton, 'click')
        const freeInputChanges = fromEvent(this.input, 'change')

        const values$: Subject<number> = new Subject()

        stepDownRequests.subscribe(() => {
            if (this.stepDown()) values$.next(this.getValue())
        })

        StepUpRequests.subscribe(() => {
            if (this.stepUp()) values$.next(this.getValue())
        })

        freeInputChanges.subscribe(() => {
            if (this.setValue(this.clampValue())) {
                values$.next(this.getValue())
            }
        })

        return values$.asObservable().pipe(
            debounceTime(300),
            distinctUntilChanged(),
        )
    }

    protected stepUp(): boolean {
        if (this.getValue() >= this.maxValue) {
            return false
        }

        return this.setValue(
            this.clampValue(
                this.getValue() + this.step,
            ),
        )
    }

    protected stepDown(): boolean {
        if (this.getValue() <= this.minValue) {
            return false
        }

        return this.setValue(
            this.clampValue(
                this.getValue() - this.step,
            ),
        )
    }

    protected clampValue(realValue = this.getValue()): number {

        if (realValue < this.minValue) {
            return this.minValue
        }

        if (realValue > this.maxValue) {
            return this.maxValue
        }

        return realValue
    }

    protected refreshButtonStates(): void {
        const currentValue = this.getValue()

        this.stepDownButton.disabled = currentValue <= this.minValue
        this.stepUpButton.disabled = currentValue >= this.maxValue
    }

    protected expectValidElements() {

        if (! (this.input instanceof HTMLInputElement)) {
            throw new Error(`Valid quantity input element not found by selector ${this.config.numberInputSelector}`)
        }

        if (! (this.stepUpButton instanceof HTMLButtonElement)) {
            throw new Error(`Did not find valid step-up button by selector ${this.config.stepUpButtonSelector}`)
        }

        if (! (this.stepDownButton instanceof HTMLButtonElement)) {
            throw new Error(`Did not find valid step-down button by selector '${this.config.stepDownButtonSelector}'.`)
        }
    }
}
