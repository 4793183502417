import { Client as BugsnagClient } from '@bugsnag/js'

import { AjaxFormFactory } from '@app-factories'
import { AddressLookupService, AjaxFormHandler } from '@app-services'

export class FoundationModal {
    private modalOptions: Record<string, string>
    private formElement: HTMLFormElement | null

    constructor(
        private readonly modalElement: HTMLElement,
        private readonly ajaxFormFactory: AjaxFormFactory,
        private readonly bugsnagClient: BugsnagClient,
    ) {
        this.initialize()
    }

    private initialize(): void {
        this.modalOptions = JSON.parse(this.modalElement.dataset.modalOptions || '{}')

        // Because of Foundation filling the modal with content
        setTimeout(() => {
            this.formElement = this.modalElement.querySelector<HTMLFormElement>('form') ||
                this.modalElement.querySelector<HTMLFormElement>(this.modalOptions.formElementSelector)

            this.attachAjaxFormHandler()

            if (this.formElement?.dataset?.addressLookup) {
                new AddressLookupService(this.formElement)
            }
        }, 1000)
    }

    private attachAjaxFormHandler(): void {
        if (! this.formElement) {
            return
        }

        const ajaxFormHandler: AjaxFormHandler = this.ajaxFormFactory.createInstance(
            this.formElement,
            this.modalOptions?.recaptchaContainerId,
        )

        this.formElement?.addEventListener('response', (response: CustomEvent) => this.handleFormResponse(response))

        this.formElement?.addEventListener('error', (error: CustomEvent) => {
            Object.keys(error.detail.body).forEach((key: string) => {
                ajaxFormHandler.updateErrorMessage(key, error.detail.body[key])
            })
        })
    }

    private handleFormResponse(response: CustomEvent): void {
        try {
            this.modalElement.innerHTML = response.detail
        } catch (error) {
            console.error('Error handling form response:', error)
            this.bugsnagClient.notify(error)
        }
    }
}
