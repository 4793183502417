import { equals, not } from 'ramda'

export const noop = () => undefined

export function isFunction(x: any): x is Function {
    return typeof x === 'function'
}

export function isString(x: any): x is string {
    return typeof x === 'string'
}

export function isNumber(x: any): x is number {
    return typeof x === 'number'
}

export const parseBase10 = (x: string): number => parseInt(x, 10)

/**
 * A catch-all fallback clause predicate for use in ramda `cond()` compositions
 */
export const otherwise = () => true

/**
 * A Ramda `equals` function alias with fixed type signature, so that it works
 * on the type-level with the distinctUntilChanged() RxJS operator.
 */
export function ducEquals<T>(a: T, b: T): boolean {
    return equals(a, b)
}

/**
 * Returns true if either the first or the second argument is true, but not both: `a XOR b`.
 */
export function either(a: boolean, b: boolean): boolean {
    return a ? not(b) : b
}

export function loadScript(src: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script')

        script.onload = () => resolve()
        script.onerror = () => reject()
        script.type = 'text/javascript'
        script.src = src

        document.head.appendChild(script)
    })
}
