import type { HtmlString } from '@app-types/common.types'
import type { VariantHit } from '../../algolia.types'
import type {
    DeliveryPromiseRecord,
} from '@app-catalog/api/catalog-api.types'

// ------------------------------------------------------------------------------
//      Variant-hit enrichment
// ------------------------------------------------------------------------------
//
//      Variant hits must be enriched -- or decorated, if you will -- with
//      real-time information in order for the algolia product-lists to properly
//      mirror the stateful representation as built by the application locally.
//      The following types and functions are used to do this.

/**
 * Generalized enricher type for variant hit data. Parameterizes the input type for variant hits
 * so that enrichers can be composed, and the final output hits type includes all enrichments.
 *
 * Enrichers are functions that take source data to enrich hits with, and return a new
 * function that takes the hits to enrich. This way enrichers can be 'piped' or 'composed'.
 *
 * @typeParam Src - The source data that is used to enrich variant hits with
 * @typeParam Dec - The enrichment type that's added to the output hits
 */
export type HitEnricher<Src, Dec> = (sourceData: Src) => <Hit extends VariantHit>(hits: Hit[]) => (Hit & Dec)[]

/**
 * The enrichment type adding the delivery-promise HTML to a variant hit.
 */
export type DeliveryPromiseEnrichment = {
    deliveryPromise: HtmlString | null
}

type U = HitEnricher<DeliveryPromiseRecord, DeliveryPromiseEnrichment>
export const enrichWithDeliveryPromises: U = (promises) => (hits) => hits.map((hit) => Object.assign(hit, {
    deliveryPromise: promises[hit.id] ?? null,
}))
