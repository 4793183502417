import { connectPagination } from 'instantsearch.js/es/connectors'
import Vue from 'vue'
import vueConfig from './pagination-widget.vue'

export interface CustomPaginationOptions {
    container: HTMLElement
}

export const createPaginationWidget = ({ container }: CustomPaginationOptions) => {

    const vue = new Vue(vueConfig(container))

    return connectPagination<CustomPaginationOptions>((options, isFirstRender: boolean) => {

        if (isFirstRender) {
            vue.$on('setPage', (page: number) => options.refine(page - 1))
        }

        vue.isFirstPage = options.isFirstPage
        vue.isLastPage = options.isLastPage
        vue.totalPages = options.nbPages
        vue.currentPage = options.currentRefinement + 1
    })({ container })
}
