import { fromEvent, merge, Observable, Subject } from 'rxjs'
import { head, isNil, not, pluck, propEq } from 'ramda'
import { map, startWith } from 'rxjs/operators'
import { queryAllWithin } from 'lambda-dom'
import type { IRadioGroup, RadioGroupConfig, RadioGroupSetup, RadioInput } from './radio-group.types'

const completeConfig = <T extends string = string>(config: Partial<RadioGroupConfig<T>>): RadioGroupConfig<T> => ({
    allowCheckedDisabled: false,
    allowAllUnchecked: true,
    allowAllDisabled: true,
    noneCheckedValue: null,
    ...config,
})

export class RadioGroup<Val extends string = string> implements IRadioGroup<Val> {

    /**
     * Creates a new RadioGroup containing all radio buttons with given name that will be found within given scope.
     */
    static createByInputName<T extends string = string>(
        inputName: string,
        setup: Partial<RadioGroupSetup<T>> = {},
        initialValue?: T,
    ): IRadioGroup<T> {
        return new RadioGroup(
            queryAllWithin(setup.scope ?? document)<RadioInput<T>>(`input[type="radio"][name="${inputName}"]`),
            completeConfig(setup.instanceConfig ?? {}),
            initialValue,
        )
    }

    public readonly values$: Observable<Val | null>
    private readonly manualValues$ = new Subject<Val | null>()

    private constructor(
        private readonly inputs: RadioInput<Val>[],
        private readonly config: RadioGroupConfig<Val>,
        initialValue?: Val,
    ) {
        const changeEvents$ = this.inputs.map((button) => fromEvent(button, 'change').pipe(
            map(() => button.value),
        ))

        if (initialValue !== undefined) {
            this.checkByValue(initialValue, false)
        }

        this.values$ = merge(this.manualValues$, ...changeEvents$).pipe(
            startWith(this.value),
        )
    }

    get value(): Val | null {
        return this.getCheckedInput()?.value ?? this.config.noneCheckedValue
    }

    public allValues(includeDisabledInputs: boolean = true): Val[] {
        return pluck('value', includeDisabledInputs ? this.inputs : this.getEnabledInputs())
    }

    public checkByValue(value: Val, emitValue: boolean = true): boolean {
        const radio = this.getInputByValue(value)

        if (isNil(radio)) {
            return false
        }

        if (radio.checked) {
            return true
        }

        if (this.config.allowAllUnchecked && radio.disabled) {
            return false
        }

        radio.checked = true

        // A change event is not dispatched for programmatic changes to the checked state
        // of a radio button. It requires this manual push of a reference to the button into
        // the stream of manual targets. This way, the value of the newly checked input is
        // emitted along with values checked through user interface.
        if (emitValue) {
            this.manualValues$.next(radio.value)
        }

        return true
    }

    public enableByValue(value: Val): boolean {
        const radio = this.getInputByValue(value)

        if (isNil(radio) || ! radio.disabled) {
            return false
        }

        radio.disabled = false
        return true
    }

    public disableByValue(value: Val, replacementValue?: Val): boolean {
        const radio = this.getInputByValue(value)

        if (isNil(radio) || radio.disabled) {
            return false
        }

        // Return false if disabling the input would violate { allowAllDisabled: false }
        if (not(this.config.allowAllDisabled) && this.countEnabled() < 2) {
            return false
        }

        if (not(this.config.allowCheckedDisabled) && radio.checked) {
            radio.checked = false

            if (not(this.config.allowAllUnchecked)) {
                const newValue = replacementValue || this.getFirstAvailableValue()

                this.manualValues$.next(newValue)
                if (newValue) {
                    this.checkByValue(newValue)
                }
            } else {
                this.manualValues$.next(null)
            }
        }

        radio.disabled = true
        return true
    }

    public uncheckCurrent(): boolean {
        const checkedInput = this.getCheckedInput()

        if (! this.config.allowAllUnchecked || isNil(checkedInput)) {
            return false
        }

        checkedInput.checked = false
        this.manualValues$.next(this.config.noneCheckedValue)
        return true
    }

    public countEnabled(): number {
        return this.getEnabledInputs().length
    }

    public getEnabledInputs(): RadioInput<Val>[] {
        return this.inputs.filter(propEq('disabled', false))
    }

    public getFirstAvailableValue(): Val | null {
        return head(this.getEnabledInputs())?.value ?? null
    }

    public getCheckedInput(): RadioInput<Val> | undefined {
        return this.inputs.find(propEq('checked', true))
    }

    public getInputByValue(value: Val): RadioInput<Val> | undefined {
        return this.inputs.find(propEq('value', value))
    }

    public hasInputWithValue(value: Val): boolean {
        return this.inputs.some(propEq('value', value))
    }
}
