export enum Environment {
    LOCAL = 'local',
    DEVELOPMENT = 'development',
    ACCEPTANCE = 'acceptance',
    PRODUCTION = 'production',
}

/**
 * Type decorator for an element `T :: HTMLElement` that merges `D` into its dataset value.
 */
export type DatasetElement<D extends Record<string, string>, T extends HTMLElement = HTMLElement> = T & {
    dataset: D
}

export type NullableElement<T extends HTMLElement = HTMLElement> = T | null | undefined

export interface DateFormats {
    compact: string
    default: string
    extended: string
    dateTime: string
}

export type HtmlString = string