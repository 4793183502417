/* eslint-disable max-len */
import { IMutationReportData } from './shopping-list.types'

// ------------------------------------------------------------------------------
//      Temporarily sold out notification at "running out of stock" moment
// ------------------------------------------------------------------------------
//
//      This function checks what the backend message says about the stock of the product. If the user adds
//      the product to the shopping cart at the same time the product is running out of stock, the notification
//      will appear and the "order funcionality" will be disabled. The solution is DOM-based, because of there
//      are no realtime (web sockets) stock updates.

const SOLD_OUT_N0TIFICATION_TIME = 5000

export function showSoldOutNotifications(data: IMutationReportData | any): void {
    // Checks if these specific error messages are sent by the backend
    if ('buyable.quantity' in data
        && ! (data['buyable.quantity'][0].includes('buyable.quantity dient minimaal'))
        && ! (data['buyable.quantity'][0].includes('Het buyable.quantity veld is vereist'))) {
        return
    }

    if ('errors' in data && data.errors.buyable.identifier !== 'Item is out of stock') {
        return
    }

    const pageIsPDP = document.querySelector('main.catalog-detail')

    if (pageIsPDP) {
        changePDPToSoldOut()
        return
    }

    const pageIsInteractiveImage = document.querySelector('.product-point-popover.open')

    if (pageIsInteractiveImage) {
        changeInteractiveImageToSoldOut()
        return
    }

    const pageIncludesRecommendedProductsList = document.querySelector('div.content[id^=product-slider-]')

    if (pageIncludesRecommendedProductsList) {
        const clickedOrderButton = document.activeElement as HTMLElement
        const clickedRecommendedProductCard = clickedOrderButton!.closest('div.recommendation')
        const productId = clickedRecommendedProductCard!.id

        const productPath = `div.recommendation#${productId}`
        changePLPToSoldOut(productPath)

        return
    }

    // Check which product is clicked (and hovered)
    const clickedProduct = document.querySelector('div.product-list article:hover') as HTMLElement

    const { buyable } = clickedProduct.dataset
    let productPath = `div.product-list article[data-buyable="${buyable}"]`

    const pageIsSearchPLP = document.querySelector('div.search-page--results')
    const pageIsCatalogPLP = document.querySelector('main.catalog-index--main')

    if (pageIsSearchPLP || pageIsCatalogPLP) {
        const { variantId } = clickedProduct.dataset
        productPath = `div.product-list article[data-variant-id="${variantId}"]`
        changePLPToSoldOut(productPath)
    }

    const pageIsTopList = document.querySelector('#top-lists-container')

    //  !!! Backend message is incorrect when stock is empty, so product is added to cart. So this feature
    //  isn't getting fired yet.
    if (pageIsTopList) {
        productPath = `.show-for-large div.product-list article[data-buyable="${buyable}"]`
        changePLPToSoldOut(productPath)
    }
}

function changePLPToSoldOut(productPath: string): void {
    const pageIsCatalogPLP = document.querySelector('main.catalog-index--main')
    const pageIsSearchPLP = document.querySelector('div.search-page--results')
    const pageIncludesRecommendedProductsList = document.querySelector('div.content[id^=product-slider-]')

    let orderButton = document.querySelector(`${productPath} .product-card--actions button[type=submit]`)

    if (pageIsCatalogPLP || pageIsSearchPLP) {
        orderButton = document.querySelector(`${productPath} .product-card--actions button[type=button]`)
    }

    const soldOutTooltip = document.querySelector(`${productPath} div.sold-out-tooltip`) as HTMLElement
    const notifyMeButton = document.querySelector(`${productPath} button[data-reveal-id="notify-me-modal"]`) as HTMLElement
    const deliveryPromise = document.querySelector(`${productPath} .delivery-time--promise`) as HTMLElement
    const deliveryPromiseAddition = document.querySelector(`${productPath} .delivery-promise-addition`) as HTMLElement
    const deliveryPromiseGreen = 'delivery-time--green'
    const deliveryPromiseGrey = 'delivery-time--grey'
    const deliveryTime = document.querySelector(`${productPath} span.${deliveryPromiseGreen}`) as HTMLElement

    // Disable "Order" button
    orderButton!.setAttribute('disabled', 'disabled')

    // Activate "Sold out" tooltip
    soldOutTooltip!.classList.remove('hide')
    soldOutTooltip!.classList.add('active')

    setTimeout(() => {
        // Hide "Order" button
        orderButton!.classList.add('hide')

        // Show "Notify me" button
        notifyMeButton?.classList.remove('hide')

        // Hide "Sold Out" tooltip
        soldOutTooltip!.classList.add('hide')
        soldOutTooltip!.classList.remove('active')

        if (! pageIncludesRecommendedProductsList) {
            // Change text to "Sold out"
            deliveryTime!.textContent = 'Tijdelijk uitverkocht'
            deliveryTime!.classList.remove(deliveryPromiseGreen)
            deliveryTime!.classList.add(deliveryPromiseGrey)

            // Remove Deliverytime promise
            deliveryPromise.classList.add('hide')
            deliveryPromiseAddition.classList.add('hide')
        }

    }, SOLD_OUT_N0TIFICATION_TIME)
}

function changeInteractiveImageToSoldOut(): void {
    const openProductPointPopOver = document.querySelector('.product-point-popover.open') as HTMLElement

    const currentProductPopOverID = openProductPointPopOver.id.split('product-point-popover-').pop()

    const productPointPopOverContainer = `#product-point-popover-${currentProductPopOverID} > div > div.details`

    const orderButton = document.querySelector(`${productPointPopOverContainer} > div.actions > form > button`) as HTMLElement
    const notifyMeButton = document.querySelector(`${productPointPopOverContainer} > div.actions > button[data-reveal-id="notify-me-modal"]`) as HTMLElement
    const soldOutTooltip = document.querySelector(`${productPointPopOverContainer} > div.actions > form > div.sold-out-tooltip`) as HTMLElement
    const soldOutText = document.querySelector(`${productPointPopOverContainer} > p.sold-out.delivery-time--grey`) as HTMLElement

    //  Show "Sold out" tooltip
    soldOutTooltip.classList.remove('hide')
    soldOutTooltip.classList.add('active')

    // Disable "Order" button
    orderButton.setAttribute('disabled', 'disabled')

    // A timeout is set, because of the duration of the progress-bar animation
    setTimeout(() => {
        // Hide "Sold Out" tooltip
        soldOutTooltip.classList.add('hide')
        soldOutTooltip!.classList.remove('active')

        // Hide "Order" button
        orderButton.classList.add('hide')

        // Show "Sold out" text
        soldOutText.classList.remove('hide')

        // Show "Notify me" button
        notifyMeButton.classList.remove('hide')

    }, SOLD_OUT_N0TIFICATION_TIME)
}

function changePDPToSoldOut(): void {
    const soldOutTooltip = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--actions > div.cart-action > form > div.sold-out-tooltip') as HTMLElement
    const orderButton = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--actions > div.cart-action > form > button') as HTMLElement
    const variantSelection = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--actions > div.cart-action > form > select') as HTMLElement
    const deliveryTime = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--delivery-info > span') as HTMLElement
    const deliveryPromise = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--delivery-info > span.delivery-time--promise') as HTMLElement
    const stockThresholdWarning = document.querySelector('#sticky-right-sidebar > div > div > div.catalog-detail--delivery-info > div.stock-threshold-warning') as HTMLElement

    soldOutTooltip.classList.remove('hide')
    soldOutTooltip.classList.add('active')

    // Remove "Almost sold out" text
    if (stockThresholdWarning) {
        stockThresholdWarning.classList.add('hide')
    }

    // Remove deliverytime promise
    deliveryPromise.classList.add('hide')

    // Hide amount dropdown
    variantSelection.classList.add('hide')

    // Disable "Order" button
    orderButton!.setAttribute('disabled', 'disabled')

    setTimeout(() => {
        // Hide "Sold Out" tooltip
        soldOutTooltip.classList.add('hide')
        soldOutTooltip!.classList.remove('active')

        // Change text to "Sold out" and text color
        deliveryTime!.textContent = 'Tijdelijk uitverkocht'
        deliveryTime!.classList.remove('delivery-time--green')
        deliveryTime!.classList.add('delivery-time--grey')

    }, SOLD_OUT_N0TIFICATION_TIME)
}
