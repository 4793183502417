import { Client } from '@bugsnag/js'
import EventDelegation from '@jjwesterkamp/event-delegation'

import { FoundationModal } from '@app-components/foundation-modal/foundation-modal.component'
import { AjaxFormFactory } from '@app-factories/ajax-form'

export class ModalFactory {
    constructor(
        private readonly ajaxFormFactory: AjaxFormFactory,
        private readonly bugsnagClient: Client,
    ) {
        this.initialize()
    }

    private initialize(): void {
        let getModalContentUrl: string = ''

        // When an element with data-reveal-ajax attribute is clicked
        document.addEventListener('DOMContentLoaded', () => {
            EventDelegation
                .global()
                .events('click')
                .select('[data-open][data-reveal-ajax]')
                .listen((e) => {
                    e.preventDefault()
                    getModalContentUrl = e.delegator.getAttribute('data-reveal-ajax') as string
                })

            $(document).on('open.zf.reveal', '[data-reveal]', (e: any): void => {
                const $modal = $(e.target)

                // TODO: change initial state to null and make this isNil check
                if (getModalContentUrl === undefined || getModalContentUrl.length < 1) {
                    // If modal has no button to open it but modal has the data-reveal-ajax attribute itself
                    getModalContentUrl = $modal.attr('data-reveal-ajax') as string
                }

                if (getModalContentUrl) {
                    // Load the modal content from request (Foundation handler)
                    $.ajax(getModalContentUrl)
                        .done(function (resp) {
                            $modal.html(resp)
                            getModalContentUrl = ''
                        })
                        .fail((jqXHR, textStatus, errorThrown) => {
                            const error = 'Request failed for getting modal content: '
                                + textStatus + ' ' + errorThrown + ' ' + jqXHR
                            console.error('Error: ', error)
                            this.bugsnagClient.notify(error)
                        })
                }

                this.createInstance(e.target as HTMLElement)
            })
        })
    }

    public createInstance(modalElement: HTMLElement): FoundationModal {
        return new FoundationModal(modalElement, this.ajaxFormFactory, this.bugsnagClient)
    }
}
