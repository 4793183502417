import type { FormDataRecord } from '@app-types/form.types'
import formDataEntries from 'form-data-entries'

function formDataGetAll(form: HTMLFormElement, searchKey: string): string[] {
    return formDataEntries(form)
        .filter(([key]) => key === searchKey)
        .map(([, value]) => value)
}

/**
 * Convert a FormData object to a plain object.
 */
export const formEntries = (form: HTMLFormElement): FormDataRecord => {
    const record: Record<string, any> = {}

    for (const [key, value] of formDataEntries(form)) {
        if (key.endsWith('[]')) {
            record[key.slice(0, -2)] = formDataGetAll(form, key)
        } else {
            record[key] = value
        }
    }

    return record
}

/**
 * @param {string} namespace - dot-notated input namespace
 * @param {boolean} asArray - If true, an additional empty bracket-pair is appended.
 */
export function inputName(namespace: string, asArray: boolean = false): string {

    const [firstSegment, ...otherSegments] = namespace.split('.')

    const otherSegmentsString = otherSegments
        .map((segment) => `[${segment}]`)
        .join('')

    return firstSegment + otherSegmentsString + (asArray ? '[]' : '')
}

export function initTextareaLengthCheck(textareaSelector: string, lengthIndicatorSelector: string): void {
    const textarea = document.querySelector<HTMLTextAreaElement>(textareaSelector)!
    const indicator = document.querySelector<HTMLElement>(lengthIndicatorSelector)!

    textarea.addEventListener('input', () => {
        const maxLength = parseInt(textarea.getAttribute('maxlength')!)
        const currentLength = textarea.value.length
        indicator.textContent = (maxLength - currentLength).toString()
    })
}
