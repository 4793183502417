export default `
<div :id="id" class="password-field-container--counter">
  <svg class="countdown-svg"
       :width="size"
       :height="size"
       :viewBox="viewBox"
       :style="svgStyle"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg">

    <circle class="countdown-background"
            :r="fillRadius"
            :cx="centerOffset"
            :cy="centerOffset"
            :stroke-dasharray="circumference"
            :style="backgroundRingStyle"
            fill="transparent"
            stroke-dashoffset="0"></circle>

    <circle class="countdown-fill"
            :r="fillRadius"
            :cx="centerOffset"
            :cy="centerOffset"
            :style="fillRingStyle"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="strokeDashoffset"
            fill="transparent"></circle>
  </svg>
</div>
`
