import { from, fromEvent, merge } from 'rxjs'
import { debounceTime, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { addClasses, removeClasses, toggleClasses } from 'lambda-dom'
import { not } from 'ramda'

const searchCache: Record<string, any> = {}

export class Search {

    protected readonly queryMinLength: number = 3

    constructor(
        private innerContainer: HTMLElement,
        private searchField: HTMLInputElement,
        private searchResultsContainer: HTMLElement,
    ) {
        const hintTriggers = merge(
            fromEvent(searchField, 'input'),
            fromEvent(searchField, 'focus'),
        ).pipe(
            filter(() => this.queryLengthOk()),
            map(() => searchField.value),
            debounceTime(500),
        )

        const escapePresses = fromEvent<KeyboardEvent>(searchField, 'keydown').pipe(
            filter((e) => e.key === 'Escape'),
        )

        const clicksOutsideComponent = fromEvent(document, 'click').pipe(
            filter((e) => ! innerContainer.contains(e.target as any)),
        )

        const shortInputValues = fromEvent(searchField, 'input').pipe(
            filter(() => not(this.queryLengthOk())),
        )

        const focusValues = fromEvent(searchField, 'focus')

        const formSubmissions = fromEvent(searchField.form!, 'submit')

        const clearHintTriggers = merge(shortInputValues, clicksOutsideComponent, escapePresses, formSubmissions)
        const blurInputTriggers = merge(clicksOutsideComponent, escapePresses)

        hintTriggers.pipe(
            tap(() => this.setLoading(true)),
            switchMap((searchQuery) => from(this.getSearchResultsHtml(searchQuery)).pipe(
                takeUntil(clearHintTriggers),
                finalize(() => this.setLoading(false)),
            )),
        )
            .subscribe((html) => this.showSearchHints(html))

        clearHintTriggers.subscribe(() => this.clearSearchHints())
        blurInputTriggers.subscribe(() => searchField.blur())

        formSubmissions
            .pipe(
                tap(() => this.setLoading(true)),
                filter(() => not(this.queryLengthOk())),
            )
            .subscribe((event) => {
                this.setLoading(false)

                event.stopPropagation()
                event.preventDefault()
            })

        focusValues.subscribe(() => searchField.select())
    }

    protected async getSearchResultsHtml(searchQuery: string): Promise<string> {

        if (! searchCache.hasOwnProperty(searchQuery)) {
            const url = this.searchField.dataset.routeHint!

            try {
                const { data: snippets } = await axios.get<string[]>(url, { params: { term: searchQuery } })
                searchCache[searchQuery] = snippets.join('')
            } catch (e) {
                bugsnagClient.notify(e)
                searchCache[searchQuery] = ''
            }
        }

        return searchCache[searchQuery]
    }

    protected queryLengthOk(): boolean {
        return this.searchField.value.length >= this.queryMinLength
    }

    protected setLoading(loading: boolean): void {
        toggleClasses('loading')(this.innerContainer, loading)
    }

    protected showSearchHints(html: string): void {
        this.searchResultsContainer.innerHTML = html
        addClasses('dropdown-active')(this.innerContainer)
    }

    protected clearSearchHints(): void {
        this.searchResultsContainer.innerHTML = ''
        removeClasses('dropdown-active')(this.innerContainer)
    }
}
