import { concat, interval, Observable, timer } from 'rxjs'
import { pairwise, repeat, startWith, take } from 'rxjs/operators'
import { queryAll } from 'lambda-dom'
import { fadeIn, fadeOut } from '@app-lib/dom.lib'

const USP_SWITCH_INTERVAL = 5000
const USP_FADE_DURATION = 200

export class UspBar {

    private readonly items = queryAll<HTMLElement>('.usp-bar--item', this.container)

    constructor(
        private readonly container: HTMLElement,
    ) {
        this.getIndexInterval().pipe(
            startWith(null),
            pairwise(),
        ).subscribe(async ([prev, curr]) => {
            await (prev === null ? Promise.resolve() : this.hideItem(prev))
            await this.showItem(curr as number)
        })
    }

    /**
     *
     */
    private getIndexInterval(): Observable<null | number> {

        const { length } = this.items

        return concat(
            timer(0, USP_SWITCH_INTERVAL).pipe(take(length)),
            interval(USP_SWITCH_INTERVAL).pipe(take(length), repeat()),
        )
    }

    /**
     *
     */
    private hideItem(index: number): Promise<void> {
        fadeOut(this.items[index], USP_FADE_DURATION)
        return new Promise((r) => setTimeout(r, USP_FADE_DURATION + 50))
    }

    /**
     *
     */
    private showItem(index: number): Promise<void> {
        fadeIn(this.items[index], USP_FADE_DURATION, 'flex')
        return new Promise((r) => setTimeout(r, USP_FADE_DURATION))
    }
}
