import { SiteHeader } from '@app-components/site-header/site-header.component'
import { mediaQuery, viewportRanges } from '@app-bootstrap/viewport-tracking.bootstrap'

export const siteHeader = SiteHeader.query('.site-header')

/**
 * This feature changes the links in the navigation items.
 */

const isDekstopViewport = window
    .matchMedia(mediaQuery({ min: viewportRanges.medium.max, max: viewportRanges.xlarge.max }))
    .matches

if (isDekstopViewport) {
    const navigationLinks = document.querySelectorAll('.mobile-menu--item') as NodeList

    if (navigationLinks.length) {
        navigationLinks.forEach((link: HTMLLinkElement) => {

            if ('href' in link.dataset) {
                if (link.dataset.href !== undefined) {
                    link.href = link.dataset.href
                }
            }

            if (link.href.includes('#nav-')) {
                link.removeAttribute('href')
            }
        })
    }
}
