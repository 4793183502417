import type {
    FavoritesMutationResponse,
    FavoritesToggle,
    FavoritesToggleForm,
} from '@app-services/favorites/favorites.types'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { BehaviorSubject } from 'rxjs'
import EventDelegation from '@jjwesterkamp/event-delegation'
import { getMeta, innerText, queryAll, showIf, touchElement } from 'lambda-dom'
import { Tracker } from '@app-services/tracker/tracker.service'
import { always, tryCatch } from 'ramda'

// ------------------------------------------------------------------------------
//      Favorites contents streams and accessor function
// ------------------------------------------------------------------------------

const contentsSubject$ = new BehaviorSubject<string[]>(
    getMeta<string[]>('current-favorites', tryCatch(JSON.parse, always([]))) ?? [],
)

export const favoritesContent$ = contentsSubject$.asObservable()

export function getFavorites(): string[] {
    return contentsSubject$.getValue()
}

// ------------------------------------------------------------------------------
//      Global event handlers for toggle form submissions
// ------------------------------------------------------------------------------

EventDelegation
    .global()
    .events('submit')
    .select<FavoritesToggleForm<'add'>>('form[data-favorites-action="add"]')
    .listen((event) => {
        event.preventDefault()
        addFavorite(event.delegator.dataset.buyable)
    })

EventDelegation
    .global()
    .events('submit')
    .select<FavoritesToggleForm<'remove'>>('form[data-favorites-action="remove"]')
    .listen((event) => {
        event.preventDefault()
        removeFavorite(event.delegator.dataset.buyable)
    })

// ------------------------------------------------------------------------------
//      Manual content update functions
// ------------------------------------------------------------------------------

export async function addFavorite(identifier: string): Promise<FavoritesMutationResponse | false> {
    try {
        const { data: response } = await axios.post<FavoritesMutationResponse>('/favorieten/buyable', {
            buyable: { identifier, quantity: 1 },
        })
        updateCounters(response.size)
        updateToggles(response.contents)
        contentsSubject$.next(response.contents)
        pushTrackingEvent(identifier, 'add')
        return response
    } catch (e) {
        return false
    }
}

export async function removeFavorite(identifier: string): Promise<FavoritesMutationResponse | false> {
    try {
        const { data: response } = await axios.post<FavoritesMutationResponse>('/favorieten/buyable', {
            buyable: { identifier },
            _method: 'DELETE',
        })
        updateCounters(response.size)
        updateToggles(response.contents)
        contentsSubject$.next(response.contents)
        pushTrackingEvent(identifier, 'remove')
        return response
    } catch (e) {
        return false
    }
}

function updateCounters(count: number): void {
    queryAll<HTMLElement>('[data-wish-list-state="distinct-item-count"]').forEach(innerText(String(count)))
}

function updateToggles(contents: string[]): void {
    queryAll<FavoritesToggle>('.wishlist-toggle').forEach((toggle) => {
        const cond = contents.includes(toggle.dataset.buyable)
        touchElement('form[data-favorites-action="remove"]', showIf(cond), toggle)
        touchElement('form[data-favorites-action="add"]', showIf(! cond), toggle)
    })
    queryAll<HTMLElement>('[data-show="wish-list-not-empty"]').forEach(showIf(contents.length > 0))
}

function pushTrackingEvent(buyableIdentifier: string, mutationEvent: string): void {
    Tracker.handleGTMFavoriteMutation(buyableIdentifier, mutationEvent)
    Tracker.handleSpotlerFavoriteMutation(buyableIdentifier, mutationEvent)
}
