import type { IndexUiState } from 'instantsearch.js/es/types/ui-state'
import type { SortByIndexDefinition } from 'instantsearch.js/es/widgets/sort-by/sort-by'

import type { HtmlString } from '@app-types/common.types'
import type { ShipmentStrategy } from '@app-catalog/api/catalog-api.types'

/**
 * Defines all filterable attributes on a list of variant hits. Server-side this enum is known
 * as the `RemoteFacetTypeEnum`.
 */
export enum FacetAttribute {
    // noinspection JSUnusedGlobalSymbols
    COLORS = 'colors',
    SHAPES = 'shapes',
    MATERIALS = 'materials',
    PRICE = 'price',
    BRAND = 'product_brand',
    CATEGORIES = 'product_categories',
    TYPE = 'type',
    AVAILABILITY = 'availability_info.satisfied_limits',
    DISCOUNTED = 'is_discounted',
    ARMREST = 'armrest',
    TOTAL_PERSONS = 'total_persons',
    WIDTH = 'width',
    HEIGHT = 'height',
    DEPTH = 'depth',
    SEAT_HEIGHT = 'seat_height',
    DIAMETER = 'diameter',
}

/**
 * Defines all filterable attributes on variants, as known locally. These values correspond
 * to URL filters used across the site.
 */
export enum LocalFacetType {
    BRAND = 'brands',
    CATEGORY = 'categories',
    COLOR = 'colors',
    SHAPES = 'shapes',
    MATERIAL = 'materials',
    PRICE = 'price',
    AVAILABILITY = 'availability',
    DISCOUNTED = 'discounted',
    ARMREST = 'armrest',
    TOTAL_PERSONS = 'total_persons',
    WIDTH = 'width',
    HEIGHT = 'height',
    DEPTH = 'depth',
    SEAT_HEIGHT = 'seat_height',
    DIAMETER = 'diameter',
}

/**
 * Defines all the different operation modes for Algolia facets.
 */
export enum FacetOperationMode {
    ANY_PASS_CHECKBOX = 'any-pass-checkbox',
    ALL_PASS_CHECKBOX = 'all-pass-checkbox',
    PRICE_RANGE = 'price-range',
    SIZE_RANGE = 'size-range',
    AVAILABILITY_LIMIT = 'availability-limit',
    TOGGLE = 'toggle',
}

/**
 * Union type of the subset of {@link FacetOperationMode} values that use the
 * checkbox 'refinementList' widgets.
 */
export type CheckboxOperationMode =
    | FacetOperationMode.ALL_PASS_CHECKBOX
    | FacetOperationMode.ANY_PASS_CHECKBOX

/**
 * Alias for the union of search operators that are legal for an Algolia `refinementList` widget.
 */
export type CheckboxOperator = 'and' | 'or'

/**
 * Defines the different availability levels for a variant. This is mirrored from the backend,
 * and determines whether a variant is purchasable or not, and whether people can get notified
 * of new stock for the respective variant.
 */
export enum AvailabilityLevel {
    // noinspection JSUnusedGlobalSymbols
    DIGITALLY_AVAILABLE = 4,
    IMMEDIATELY_AVAILABLE = 3,
    AVAILABLE_OVER_TIME = 2,
    TEMPORARILY_UNAVAILABLE = 1,
    PERMANENTLY_UNAVAILABLE = 0,
}

export enum AvailabilityDateLimit {
    IMMEDIATE = 'immediate',
    MAX_2_WEEKS = 'max-2-weeks',
    MAX_3_WEEKS = 'max-3-weeks',
    MAX_6_WEEKS = 'max-6-weeks',
}

// ------------------------------------------------------------------------------
//      Variant hit data
// ------------------------------------------------------------------------------
/**
 * The raw data shape of an Algolia hit for a variant.
 */
export interface VariantHit {
    __position: number
    __queryID?: string
    id: number
    slug: string
    title: string
    url: string
    is_top_product: boolean
    is_basiclabel_exclusive?: boolean
    rating: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
    availability_level: AvailabilityLevel
    availability_info: {
        html: HtmlString
        plain_text: string
        shipment_strategy: ShipmentStrategy
        stock: number
    }
    main_image: string
    aux_image: string | null
    created_at: string
    description: string
    distinct: string
    ean: string
    item_code: string
    colors: string[]
    shapes: string[]
    total_persons: string[]
    materials: string[]
    objectID: string
    /**
     * Price in cents
     */
    price: number
    discounted_price: number
    is_discounted: boolean
    product_id: number
    product_slug: string
    product_title: string
    product_brand: string
    product_categories: string[][]
    product_description: string
    product_unique_selling_points: HtmlString
    product_url: string
    product_badge: null | {
        image_url: string
        background: string | null
    }
    unit_quantity: number
    type: 'variant'
    armrest: string[]
    width: number
    height: number
    depth: number
    seat_height: number
    diameter: number
}

// ------------------------------------------------------------------------------
//      Setup interfaces
// ------------------------------------------------------------------------------

/**
 * Defines a sorting strategy, and associates it with an Algolia index. This extends
 * the native Algolia data structure so that it can be given to sorting widgets directly.
 */
export interface SortIndex extends SortByIndexDefinition {
    slug: string
    isDefault: boolean
}

/**
 * A map of model identifiers, key by the name as known by Algolia, their values
 * are the local model IDs.
 */
export type ModelIdMap = { [key: string]: string | undefined }

/**
 * Defines the entire set of data required to initialise an instantsearch runtime,
 * including authentication data, facets to render, the different search indices
 * to use for sorting and the initial UI/filter state as determined by the backend.
 */
export interface InstantSearchSetup {
    appId: string
    apiKey: string
    facetSetup: FacetSetup
    sortIndices: SortIndex[]
    pageSize: number
    indexName: string
    initialUIState: IndexUiState
    modelIdMap: Partial<Record<LocalFacetType, ModelIdMap>>
}

export type EnhancedIndexUiState = Partial<IndexUiState & { first: boolean }>

/**
 * Defines the set of facets in use for an instantsearch runtime.
 */
export type FacetSetup = FacetSetupEntry[]

/**
 * Defines identifiers and operation mode for a single Algolia facet.
 */
export interface FacetSetupEntry {
    localType: LocalFacetType
    remoteType: FacetAttribute
    operationMode: FacetOperationMode
}

export type FacetContainers = Partial<Record<FacetAttribute, HTMLElement[] | null>>

/**
 * Defines the DOM context for an instantsearch runtime -- the elements to render facets
 * and other widgets in.
 */
export interface InstantSearchContext {
    listContainer: HTMLElement
    sortContainers: HTMLElement[]
    paginationContainers: HTMLElement[]
    resultCountContainers: HTMLElement[]
    clearRefinementsContainers: HTMLElement[]
    clearRefinementsLinks: HTMLElement[]
    facetContainers: FacetContainers
    /**
     * Optional: scroll to this element whenever the product list changes.
     */
    scrollAnchor?: HTMLElement
}

/**
 * Defines a filter on the range facet.
 */
export interface RangeFilter {
    /**
     * The range lower bound (in EUROs, not cents)
     */
    min: number
    /**
     * The range upper bound (in EUROs, not cents)
     */
    max: number
}

/**
 * The data used in the backend for an algolia event.
 */
export interface EventData {
    eventName: string,
    eventType: string,
    index: string,
    queryID?: string,
    objectID: string,
}

/**
 * Defines the range filters and their config
 */
interface FilterConfig {
    range: string
    minInput: string
    maxInput: string
}
interface Filters {
    price: FilterConfig,
    width: FilterConfig,
    height: FilterConfig,
    depth: FilterConfig,
    seatHeight: FilterConfig,
    diameter: FilterConfig,
}
export const filtersConfig: Filters = {
    price: {
        range: '#price-range',
        minInput: '#price-min-input',
        maxInput: '#price-max-input',
    },
    width: {
        range: '#size-width-range',
        minInput: '#size-width-min-input',
        maxInput: '#size-width-max-input',
    },
    height: {
        range: '#size-height-range',
        minInput: '#size-height-min-input',
        maxInput: '#size-height-max-input',
    },
    depth: {
        range: '#size-depth-range',
        minInput: '#size-depth-min-input',
        maxInput: '#size-depth-max-input',
    },
    seatHeight: {
        range: '#size-seat_height-range',
        minInput: '#size-seat_height-min-input',
        maxInput: '#size-seat_height-max-input',
    },
    diameter: {
        range: '#size-diameter-range',
        minInput: '#size-diameter-min-input',
        maxInput: '#size-diameter-max-input',
    },
}
