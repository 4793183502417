/**
 * @see /app/Enums/Checkout/Transaction/TransactionStatusEnum
 */
export enum TransactionStatus {
    SUCCESS = 'success',
    PENDING = 'pending',
    ERROR = 'error',
    CANCELLED = 'cancelled',
}

export const endpoints = {
    transactionAbort: (origin: string, id: string) => `${origin}/remote-checkout/transaction/${id}/abort`,
    transactionStatus: (origin: string, id: string) => `${origin}/remote-checkout/transaction/${id}/status`,
    transactionReceipt: (origin: string, id: string) => `${origin}/remote-checkout/receipt/transaction/${id}`,
    orderConfirmation: (origin: string, orderId: number) => `${origin}/remote-checkout/order/${orderId}/confirmation`,
}
