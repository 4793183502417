import { BuyableAction, BuyableForm } from '@app-services/shopping-list/shopping-list.types'

export class CartMutationFormSubmission {

    static create(form: BuyableForm, event?: Event): CartMutationFormSubmission {

        if (! (form instanceof HTMLFormElement)) {
            throw new TypeError('Argument \'form\' must be an instance of HTMLFormElement')
        }

        const idInput = form.querySelector<HTMLInputElement>('[name="buyable[identifier]"]')
        const quantityInput = form.querySelector<HTMLInputElement>('[name="buyable[quantity]"]')

        if (! idInput) {
            throw new TypeError('Given form element does not contain all required inputs')
        }

        return new this(form, idInput, quantityInput, event)
    }

    constructor(
        private readonly form: BuyableForm,
        protected readonly idInput: HTMLInputElement,
        protected readonly quantityInput: HTMLInputElement | null,
        event?: Event,
    ) {
        event?.preventDefault()
    }

    buyableAction(): BuyableAction {
        return this.form.dataset.buyableAction ?? ''
    }

    buyableIdentifier(): string {
        return this.idInput.value
    }

    buyableQuantity(): number | undefined {
        return this.quantityInput
            ? Number(this.quantityInput.value)
            : undefined
    }

    disableServiceButton(identifier: string): void {
        const addServiceButton: HTMLButtonElement = this.form.querySelector(identifier) as HTMLButtonElement

        if (addServiceButton) {
            addServiceButton.disabled = true
        }
    }
}
