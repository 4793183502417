import { combineLatest, fromEventPattern, Observable } from 'rxjs'
import { map, pluck, startWith } from 'rxjs/operators'
import { any, identity } from 'ramda'

export interface ViewportRangeBounds {
    min: number
    max: number
}

export enum ViewportRange {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    XLarge = 'xlarge',
}

export const viewportRanges: { [_ in ViewportRange]: ViewportRangeBounds } = {
    [ViewportRange.Small]: {
        min: 0,
        max: 640,
    },
    [ViewportRange.Medium]: {
        min: 641,
        max: 1024,
    },
    [ViewportRange.Large]: {
        min: 1025,
        max: 1440,
    },
    [ViewportRange.XLarge]: {
        min: 1441,
        max: 10000,
    },
}

export const mediaQuery = ({ min, max }: ViewportRangeBounds) => `(min-width: ${min}px) and (max-width: ${max}px)`

const createMatchesObservable = (range: ViewportRangeBounds): Observable<boolean> => fromEventPattern(
    (handler) => window.matchMedia(mediaQuery(range)).addEventListener('change', handler),
).pipe(
    startWith(window.matchMedia(mediaQuery(range))),
    pluck('matches'),
)

export const matches: { [_ in ViewportRange]: Observable<boolean> } = {
    [ViewportRange.Small]: createMatchesObservable(viewportRanges.small),
    [ViewportRange.Medium]: createMatchesObservable(viewportRanges.medium),
    [ViewportRange.Large]: createMatchesObservable(viewportRanges.large),
    [ViewportRange.XLarge]: createMatchesObservable(viewportRanges.xlarge),
}

export const breakpointMatchAny = (ranges: ViewportRange[]): Observable<boolean> => combineLatest(
    ranges.map((range) => matches[range]),
).pipe(map(any(identity)))
