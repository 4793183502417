import { querySearch } from '@app-components/search/search.factory'
import { fromEvent, merge, Subject } from 'rxjs'
import { debounceTime, startWith } from 'rxjs/operators'
import { addClasses, removeClasses, toggleClasses } from 'lambda-dom'
import { CssVariableService } from '../css-variable.service'

const cssVariableService = new CssVariableService()

// ------------------------------------------------------------------------------
//      Elements
// ------------------------------------------------------------------------------

const html = document.querySelector('html') as HTMLElement
const fakeFocus = document.getElementById('fake-focus') as HTMLInputElement
const fakeFocusCollapsed = document.getElementById('fake-focus-collapsed') as HTMLInputElement
const mobileHeader = document.getElementById('mobile-header') as HTMLElement
const mobileSearch = mobileHeader.querySelector('.mobile-header--search') as HTMLElement
const searchMask = mobileHeader.querySelector('.mobile-header--mask') as HTMLElement
const searchButton = mobileHeader.querySelector('.search-button') as HTMLButtonElement
const searchInput = mobileHeader.querySelector('.global-search--field') as HTMLInputElement
const searchBack = mobileHeader.querySelector('#search-back') as HTMLButtonElement
const searchClear = mobileHeader.querySelector('.global-search--clear') as HTMLButtonElement
const globalSearch = mobileHeader.querySelector('.global-search') as HTMLElement

// ------------------------------------------------------------------------------
//      Config
// ------------------------------------------------------------------------------

const HEADER_COLLAPSE_THRESHOLD = 120
const HEADER_COLLAPSE_CLASS = 'collapsed'
const MASK_ACTIVE_CLASS = 'active'
const SEARCH_SHOW_CLASS = 'show'
const SEARCH_OPEN = 'search-open'

// ------------------------------------------------------------------------------
//      Lib
// ------------------------------------------------------------------------------

const collapseHeader = (): void => {
    mobileHeader.classList.add(HEADER_COLLAPSE_CLASS)
    html?.classList.add('header-is-collapsed')
    cssVariableService.setVariable('hide-when-collapsed', 'none')
    cssVariableService.setVariable('show-when-collapsed', 'unset')
}
const expandHeader = (): void => {
    mobileHeader.classList.remove(HEADER_COLLAPSE_CLASS)
    html?.classList.remove('header-is-collapsed')
    cssVariableService.setVariable('hide-when-collapsed', 'unset')
    cssVariableService.setVariable('show-when-collapsed', 'none')
}

const foldHeader = (): void => {
    window.scrollTo(0, 0)
    toggleFakes(false)
    showSearchBar()
    showMask()
    collapseHeader()
    setTimeout(() => {
        searchInput.focus()
        window.scrollTo(0, 0)
    }, 300)
}

const showMask = (): void => {
    searchMask.classList.add(MASK_ACTIVE_CLASS)
    document.body.style.overflow = 'hidden'
}

const hideMask = (): void => {
    searchMask.classList.remove(MASK_ACTIVE_CLASS)
    document.body.style.overflow = ''
}

const showSearchBar = (): void => {
    html?.classList.add('search-is-shown')
    mobileSearch.classList.add(SEARCH_SHOW_CLASS)
    mobileHeader.classList.add(SEARCH_OPEN)
}
const hideSearchBar = (): void => {
    html?.classList.remove('search-is-shown')
    mobileSearch.classList.remove(SEARCH_SHOW_CLASS)
    mobileHeader.classList.remove(SEARCH_OPEN)
}

const toggleFakes = (show: boolean) => {
    if (show) {
        fakeFocus.style.display = 'block'
        fakeFocusCollapsed.style.display = 'block'
    } else {
        fakeFocus.style.display = 'none'
        fakeFocusCollapsed.style.display = 'none'
    }
}

const blurSearchInput = (): void => {
    searchInput.blur()
    toggleFakes(true)
}

const scrollTop = () => {
    return window.scrollY
}

const scrollDetect = () => {
    const currentScroll = scrollTop()
    const headerClasses = mobileHeader.classList
    const htmlClasses = html.classList
    if (
        currentScroll < HEADER_COLLAPSE_THRESHOLD
        && headerClasses.contains('collapsed')
        && ! htmlClasses.contains('search-is-shown')
    ) {
        expandHeader()
    }
    if (
        currentScroll > HEADER_COLLAPSE_THRESHOLD
        && ! headerClasses.contains('collapsed')
    ) {
        collapseHeader()
    }
}

// ------------------------------------------------------------------------------
//      Event listeners
// ------------------------------------------------------------------------------

fakeFocus.addEventListener('click', () => {
    foldHeader()
})

fakeFocusCollapsed.addEventListener('click', () => {
    foldHeader()
})

searchButton.addEventListener('click', () => {
    foldHeader()
})

searchBack.addEventListener('click', () => {
    blurSearchInput()
    hideSearchBar()
    hideMask()
    expandHeader()
    fakeFocusCollapsed.style.display = 'none'
})

searchMask.addEventListener('click', () => {
    blurSearchInput()
    hideSearchBar()
    hideMask()
    expandHeader()
})

window.addEventListener('scroll', () => {
    scrollDetect()
})

// ------------------------------------------------------------------------------
//      Observables
// ------------------------------------------------------------------------------

const manualSearchInput = new Subject()
const searchInputTyping = merge(
    fromEvent(searchInput, 'input'),
    manualSearchInput,
).pipe(
    debounceTime(50),
    startWith(),
)

const clearInputClicks = fromEvent(searchClear, 'click')

// ------------------------------------------------------------------------------
//      Runtime
// ------------------------------------------------------------------------------

searchInputTyping.subscribe(() => {
    if (searchInput.value.length) {
        removeClasses('hidden')(searchClear)
    } else {
        addClasses('hidden')(searchClear)
    }
})

clearInputClicks.subscribe(() => {
    searchInput.value = ''
    manualSearchInput.next()
    removeClasses('dropdown-active')(globalSearch)
})

// ------------------------------------------------------------------------------
//      Public API
// ------------------------------------------------------------------------------

export const defeatShadow = (defeat: boolean): void => toggleClasses('defeat-shadow')(mobileHeader, defeat)
querySearch('.mobile-header--search', mobileHeader)
