function fetchSVGSprite(): Promise<string> {
    return fetch('/build/svg-sprite.svg')
        .then((response) => response.text())
        .catch(() => Promise.reject())
}

export function initSVGSprite(svgSpriteContainer: HTMLElement): void {
    fetchSVGSprite()
        .then((data) => svgSpriteContainer.innerHTML = data)
        .catch(() => {})
}
