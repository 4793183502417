import { HttpClient } from '@app-services'
import { RECAPTCHA_INVISIBLE_SITEKEY } from '@app-bootstrap/recaptcha.bootstrap'

export class AjaxFormHandler {

    private recaptchaWidgetId: number | null = null
    private resolveRecaptcha: ((value: unknown) => void)

    constructor(
        private readonly formElement: HTMLFormElement,
        private readonly httpClient: HttpClient,
        private readonly recaptchaContainerId: string | null = null,
    ) {
        if (this.recaptchaContainerId) {
            this.initializeRecaptcha()
        }

        this.attachListener()
    }

    private initializeRecaptcha(): void {
        if (! this.recaptchaContainerId) {
            return
        }

        this.recaptchaWidgetId = grecaptcha.render(this.recaptchaContainerId, {
            size: 'invisible',
            sitekey: RECAPTCHA_INVISIBLE_SITEKEY,
            callback: () => this.resolveRecaptcha(true),
        })
    }

    private attachListener(): void {
        this.formElement.addEventListener('submit', async (e: SubmitEvent) => {
            e.preventDefault()

            if (! this.formElement.checkValidity()) {
                this.validateInputs()
                return
            }

            if (this.recaptchaWidgetId !== null) {
                new Promise((resolve) => {
                    this.resolveRecaptcha = resolve
                    grecaptcha.execute(this.recaptchaWidgetId)
                }).then(() => {
                    this.submitForm()
                })
            } else {
                this.submitForm()
            }
        })
    }

    private async submitForm(): Promise<void> {
        try {
            const formData = new FormData(this.formElement)

            if (this.recaptchaContainerId) {
                formData.append('recaptcha-strategy', 'invisible')
            }

            const response = await this.httpClient.post(
                this.formElement.action,
                {},
                { 'Accept': 'application/json' },
                formData,
            )

            this.formElement.dispatchEvent(new CustomEvent('response', { detail: response }))
        } catch (error) {
            this.formElement.dispatchEvent(new CustomEvent('error', { detail: error }))

            if (this.recaptchaWidgetId) {
                grecaptcha.reset(this.recaptchaWidgetId)
            }
        }
    }

    private validateInputs(): void {
        const inputs = this.formElement.querySelectorAll<HTMLInputElement>('input')

        inputs.forEach((input: HTMLInputElement) => {
            const errorContainer: HTMLElement | null = input.nextElementSibling as HTMLElement
            if (! errorContainer) {
                return
            }

            input.setCustomValidity('')

            const errorMessageElement = errorContainer.querySelector('.input-wrapper--error--content')

            if (errorMessageElement) {
                if (! input.checkValidity()) {
                    const customMessage = input.getAttribute('data-error') || ''
                    input.setCustomValidity(customMessage)

                    errorMessageElement.textContent = input.validationMessage
                    errorContainer.classList.add('show')
                } else {
                    errorMessageElement.textContent = ''
                    errorContainer.classList.remove('show')
                }
            }
        })
    }

    public updateErrorMessage(inputName: string, newErrorMessage: string): void {
        const inputElement = this.formElement.querySelector<HTMLInputElement>(`input[name="${inputName}"]`)

        if (inputElement) {
            const errorContainer: HTMLElement | null = inputElement?.nextElementSibling as HTMLElement
            if (! errorContainer) return

            const errorMessageElement = errorContainer?.querySelector('.input-wrapper--error--content')

            if (errorMessageElement) {
                errorMessageElement.textContent = newErrorMessage
                errorContainer.classList.add('show')
            }
        }
    }

    public clearErrors(): void {
        const errorContainers = this.formElement.querySelectorAll<HTMLElement>('.input-wrapper--error')

        errorContainers.forEach((errorContainer: HTMLElement) => {
            errorContainer.classList.remove('show')
        })
    }
}
