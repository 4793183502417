import type { TraceHooks } from '@app-lib/debugging/decorators/traced.decorator'
import { badge, colored, colors, expandedLog } from '@app-lib/debugging/formatters/console.formatters'
import type { StateMapperComponent } from './state-mapper.component'

const logCallStack = (): void => {
    console.groupCollapsed(...colored('call stack', colors.gray))
    console.trace()
    console.groupEnd()
}

const logState = (state: any, name: string, color: string): void => {
    console.group(...colored(name, color))
    expandedLog(state)
    console.groupEnd()
}

export const routeToStateTrace: TraceHooks<StateMapperComponent['routeToState']> = {
    before([routeState]) {
        console.groupCollapsed(...badge('StateMapperComponent :: routeToState()', colors.lime))
        logCallStack()
        logState(routeState, 'Route state', colors.blue)
    },

    after(uiState) {
        logState(uiState, 'UI state', colors.lime)
        console.groupEnd()
    },
}

export const stateToRouteTrace: TraceHooks<StateMapperComponent['stateToRoute']> = {
    before([uiState]) {
        console.groupCollapsed(...badge('StateMapperComponent :: stateToRoute()', colors.blue))
        logCallStack()
        logState(uiState, 'UI state', colors.lime)
    },

    after(routeState) {
        logState(routeState, 'Route state', colors.blue)
        console.groupEnd()
    },
}
