import { flatten } from 'ramda'
import axios from 'axios'
import { API as noUiSliderAPI } from 'nouislider'
import algoliasearch from 'algoliasearch'
import instantsearch, { InstantSearch } from 'instantsearch.js'
import { configure, sortBy } from 'instantsearch.js/es/widgets'
import { createInsightsMiddleware, InsightsEvent } from 'instantsearch.js/es/middlewares'

import { InsightsClientMethod } from 'instantsearch.js/es/types'
import type { EnhancedIndexUiState, InstantSearchContext, InstantSearchSetup } from './algolia.types'

import { scrollToElement } from '@app-lib/scrolling.lib'

import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import {
    createClearRefinementsLinkWidget,
    createClearRefinementsWidget,
    createFacetWidget,
    createHistoryRouter,
    createResultsCountWidget,
} from './algolia.lib'
import { createPaginationWidget } from './connectors/pagination/pagination-widget.index'
import { createProductListWidget } from './connectors/product-list/product-list-widget.index'
import { StateMapperComponent } from './components/state-mapper/state-mapper.component'

const searchInsights = require('search-insights')

export type UserTokenResponse = {
    token: string | null
}

export async function fetchUserToken(): Promise<string | null> {
    const userToken = searchInsights('getUserToken') ?? null

    if (userToken === null) {
        const data = await axios.get<UserTokenResponse>('/algolia/user-token')
            .then((value) => value.data)
        return data.token
    }

    return userToken
}

export function buildInstantSearchUi(
    setup: InstantSearchSetup,
    context: InstantSearchContext,
    priceSlider?: noUiSliderAPI,
    sizeWidthSlider?: noUiSliderAPI,
    sizeHeightSlider?: noUiSliderAPI,
    sizeDepthSlider?: noUiSliderAPI,
    sizeSeatHeightSlider?: noUiSliderAPI,
    sizeDiameterSlider?: noUiSliderAPI,
): InstantSearch {
    const { apiKey, appId, indexName, initialUIState } = setup

    let lastPage: number | undefined = initialUIState?.page

    const search = instantsearch({
        indexName,
        searchClient: algoliasearch(appId, apiKey),
        initialUiState: { [indexName]: initialUIState },
        routing: {
            router: createHistoryRouter(),
            stateMapping: new StateMapperComponent(setup, {
                min: priceSlider?.options?.range?.min as number,
                max: priceSlider?.options?.range?.max as number,
            }),
        },
        onStateChange({ uiState, setUiState }): void {
            setUiState(uiState)

            const currentPage = uiState[indexName]?.page
            const { first } = uiState[indexName] as EnhancedIndexUiState

            if (currentPage !== lastPage) {
                lastPage = currentPage

                if (context.scrollAnchor && ! first) {
                    const { y } = context.scrollAnchor.getBoundingClientRect()
                    if (y < 0) {
                        scrollToElement(context.scrollAnchor)
                    }
                }
            }

            // Temporary workaround: https://github.com/algolia/instantsearch.js/issues/4795#issuecomment-868425164
            try {
                // @ts-ignore
                this.middleware.forEach(({ instance }) => {
                    instance.onStateChange({ uiState })
                })
            } catch (e) {
                bugsnagClient.notify(e)
            }
        },
    })

    fetchUserToken().then((value) => {
        if (value !== null) {
            searchInsights('setUserToken', value)
        }
    })

    const insightsMiddleware = createInsightsMiddleware({
        insightsClient: searchInsights,
        onEvent: (event: InsightsEvent, insightsClient) => {
            const { insightsMethod, payload } = event
            const { objectIDs } = payload
            if (objectIDs) {
                const maxProductsPerEvent = 20
                for (let i = 0; i < objectIDs.length; i += maxProductsPerEvent) {
                    event.payload.objectIDs = objectIDs.slice(i, i + maxProductsPerEvent)

                    if (insightsClient) {
                        try {
                            insightsClient((insightsMethod as InsightsClientMethod), payload)
                        } catch (e) {
                            // Suppress illegal invocation error
                        }
                    }
                }
            }
        },
    })

    search.use(insightsMiddleware)

    search.addWidgets([
        configure({
            filters: setup.initialUIState.configure!.filters,
            hitsPerPage: setup.pageSize,
            clickAnalytics: true,
        }),
        createProductListWidget({
            container: context.listContainer,
        }),
    ])

    search.addWidgets(context.resultCountContainers.map(createResultsCountWidget))
    search.addWidgets(
        flatten(setup.facetSetup.map((entry) => createFacetWidget(
            entry,
            context,
            priceSlider,
            sizeWidthSlider,
            sizeHeightSlider,
            sizeDepthSlider,
            sizeSeatHeightSlider,
            sizeDiameterSlider,
        ))),
    )
    search.addWidgets(context.paginationContainers.map((container) => createPaginationWidget({ container })))
    search.addWidgets(context.sortContainers.map((container) => sortBy({ container, items: setup.sortIndices })))
    search.addWidgets(context.clearRefinementsContainers.map(createClearRefinementsWidget))
    search.addWidgets(context.clearRefinementsLinks.map(createClearRefinementsLinkWidget))

    return search
}
