import { equals, isEmpty, isNil, not, toPairs } from 'ramda'

import type { RouteState, StateMapping, UiState } from 'instantsearch.js/es/types'
import {
    AvailabilityDateLimit,
    EnhancedIndexUiState,
    FacetOperationMode,
    InstantSearchSetup,
    LocalFacetType,
} from '../../algolia.types'

import type { ParsedIdList, ParsedNumericRange, ParsedRouteState } from '../../lib/route-state.lib'
import { traced } from '@app-lib/debugging/decorators/traced.decorator'
import {
    findSortingByIndexName,
    findSortingBySlug,
    formatPriceRange,
    formatSizeRange,
    getDefaultSorting,
    parseRange,
} from '../../algolia.lib'

import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { routeToStateTrace, stateToRouteTrace } from './state-mapper.tracing'

/**
 * A class implementation of the Algolia state-mapping contract for transforming the
 * instantsearch UI state to route state and vice versa. See the
 * {@link https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/js/ routing documentation}
 * for more information.
 */
export class StateMapperComponent implements StateMapping {
    private first = true

    constructor(
        protected readonly setup: InstantSearchSetup,
        protected readonly range: ParsedNumericRange,
    ) {
    }

    /**
     * Transforms a route-state into an algolia UI state. The route state is an object that contains
     * the filter state structured as used in the browser URL. Important note is that to keep things
     * working properly, it should always have the exact same shape for the same state, as Algolia
     * performs equality checks on the shape of the object. This means that a property set with a
     * value of undefined might not be considered the same as that same property omitted. Therefore
     * we only create properties once they're actually needed.
     */
    @traced(routeToStateTrace)
    public routeToState(routeState: ParsedRouteState): UiState {

        const indexUiState: EnhancedIndexUiState = {
            configure: this.setup.initialUIState?.configure,
        }

        if (routeState.page) {
            indexUiState.page = routeState.page
        }

        const sorting = findSortingBySlug(this.setup, routeState.sorting) || getDefaultSorting(this.setup)!

        if (sorting.value !== this.setup.indexName) {
            indexUiState.sortBy = sorting.value
        }

        for (const entry of this.setup.facetSetup) {
            switch (entry.operationMode) {
                case FacetOperationMode.ALL_PASS_CHECKBOX:
                case FacetOperationMode.ANY_PASS_CHECKBOX: {
                    const values = this.mapIdsToNames(
                        entry.localType,
                        (routeState.filter?.[entry.localType] || []) as ParsedIdList,
                    )

                    if (not(isEmpty(values))) {
                        indexUiState.refinementList = indexUiState.refinementList || {}
                        indexUiState.refinementList[entry.remoteType] = values
                    }
                    break
                }

                case FacetOperationMode.PRICE_RANGE: {
                    const priceFilter = routeState.filter?.[entry.localType] as ParsedNumericRange | undefined

                    if (priceFilter && ! equals(priceFilter, this.range)) {
                        indexUiState.range = indexUiState.range || {}
                        indexUiState.range[entry.remoteType] = formatPriceRange(priceFilter)
                    }
                    break
                }

                case FacetOperationMode.SIZE_RANGE: {
                    const sizeFilter = routeState.filter?.[entry.localType] as ParsedNumericRange | undefined

                    if (sizeFilter && ! equals(sizeFilter, this.range)) {
                        indexUiState.range = indexUiState.range || {}
                        indexUiState.range[entry.remoteType] = formatSizeRange(sizeFilter)
                    }
                    break
                }

                case FacetOperationMode.AVAILABILITY_LIMIT: {
                    const limitFilter = routeState.filter?.[entry.localType] as AvailabilityDateLimit | undefined

                    if (! isNil(limitFilter)) {
                        indexUiState.menu = indexUiState.menu || {}
                        indexUiState.menu[entry.remoteType] = limitFilter
                    }
                    break
                }
                case FacetOperationMode.TOGGLE: {
                    const toggleFilter = routeState.filter?.[entry.localType]

                    if (! isNil(toggleFilter)) {
                        indexUiState.toggle = indexUiState.toggle || {}
                        indexUiState.toggle[entry.remoteType] = toggleFilter === 'true'
                    }
                    break
                }
            }
        }

        if (this.first) {
            indexUiState.first = true
            this.first = false
        }

        return {
            [this.setup.indexName]: indexUiState,
        }
    }

    /**
     * Transforms an Algolia UI state object to a route-state object as used for the browser URL.
     * For this method the same note applies as expressed for {@link routeToState}: we only create
     * properties once they're needed, because otherwise two equivalent states can be considered
     * semantically different by Algolia, resulting in multiple history entries that reflect the
     * same state.
     */
    @traced(stateToRouteTrace)
    public stateToRoute(uiState: UiState): ParsedRouteState {

        const indexUiState = uiState[this.setup.indexName]
        const routeState: RouteState = {}

        routeState.page = (indexUiState.page !== 1 ? indexUiState.page : undefined)

        const sorting = findSortingByIndexName(this.setup, indexUiState.sortBy || this.setup.indexName)

        if (sorting && not(sorting.isDefault)) {
            routeState.sorting = sorting.slug
        }

        for (const entry of this.setup.facetSetup) {
            switch (entry.operationMode) {
                case FacetOperationMode.ALL_PASS_CHECKBOX:
                case FacetOperationMode.ANY_PASS_CHECKBOX: {
                    const ids = this.mapNamesToIds(
                        entry.localType,
                        indexUiState.refinementList?.[entry.remoteType] || [],
                    )

                    if (! isEmpty(ids)) {
                        routeState.filter = routeState.filter || {}
                        routeState.filter[entry.localType] = ids
                    }

                    break
                }

                case FacetOperationMode.PRICE_RANGE: {
                    const priceFilter = parseRange(indexUiState.range?.[entry.remoteType], true)

                    if (priceFilter && ! equals(priceFilter, this.range)) {
                        routeState.filter = routeState.filter || {}
                        routeState.filter[entry.localType] = priceFilter
                    }

                    break
                }

                case FacetOperationMode.SIZE_RANGE: {
                    const sizeFilter = parseRange(indexUiState.range?.[entry.remoteType])

                    if (sizeFilter && ! equals(sizeFilter, this.range)) {
                        routeState.filter = routeState.filter || {}
                        routeState.filter[entry.localType] = sizeFilter
                    }

                    break
                }

                case FacetOperationMode.AVAILABILITY_LIMIT: {
                    const limit = indexUiState.menu?.[entry.remoteType]

                    if (! isNil(limit)) {
                        routeState.filter = routeState.filter || {}
                        routeState.filter[entry.localType] = limit
                    }
                    break
                }

                case FacetOperationMode.TOGGLE: {
                    const toggle = indexUiState.toggle?.[entry.remoteType]

                    if (toggle) {
                        routeState.filter = routeState.filter || {}
                        routeState.filter[entry.localType] = true
                    }

                    break
                }
            }
        }

        return routeState
    }

    protected mapIdsToNames(localType: LocalFacetType, ids: ParsedIdList): string[] {

        const names: string[] = []
        const map = toPairs(this.setup.modelIdMap[localType] || {})

        for (const searchId of ids) {
            const tuple = map.find(([, findId]) => findId === searchId)
            if (tuple) {
                names.push(tuple[0])
            } else {
                bugsnagClient.notify(
                    `INSTANTSEARCH - Issue while mapping IDs to names: did not find name for ${localType}:${searchId}`,
                )
            }
        }

        return names
    }

    protected mapNamesToIds(localType: LocalFacetType, names: string[]): ParsedIdList {

        const map = this.setup.modelIdMap[localType]

        const ids: ParsedIdList = []

        for (const name of names) {
            const id = map?.[name]
            if (id) {
                ids.push(id)
            } else {
                bugsnagClient.notify(
                    `INSTANTSEARCH - Issue while mapping names to IDs: did not find ID for ${localType}:${name}`,
                )
            }
        }

        return ids
    }
}
