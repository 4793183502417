import { clone } from 'ramda'
import { IMutationReport, IMutationReportData, MutationType } from './shopping-list.types'
import { showSoldOutNotifications } from './sold-out-notifications'

export class MutationReport implements IMutationReport {

    constructor(protected data: IMutationReportData) {
        if ('buyable.quantity' in data || 'errors' in data) {
            showSoldOutNotifications(data)
        }
    }

    public buyableIdentifier(): string {
        return this.data.buyable_identifier
    }

    public previousQuantity(): number {
        return this.data.previous_quantity
    }

    public currentQuantity(): number {
        return this.data.current_quantity
    }

    public mutationType(): MutationType {

        return this.inferMutationType(
            this.previousQuantity(),
            this.currentQuantity(),
        )
    }

    public realQuantityDelta(): number {
        return this.currentQuantity() - this.previousQuantity()
    }

    public attemptedQuantity(): number {
        return this.data.attempted_quantity
    }

    public attemptedQuantityDelta(): number {
        return this.attemptedQuantity() - this.previousQuantity()
    }

    public attemptedMutationType(): MutationType {
        return this.inferMutationType(
            this.previousQuantity(),
            this.currentQuantity(),
        )
    }

    public toJSONSerializable(): IMutationReportData {
        return clone(this.data)
    }

    /**
     * Determine the mutation type for given 'prev' and 'next' quantities, context agnostic.
     *
     * @param prevQuantity
     * @param nextQuantity
     */
    protected inferMutationType(prevQuantity: number, nextQuantity: number): MutationType {

        if (prevQuantity === nextQuantity) {
            return MutationType.NULL
        }

        if (prevQuantity === 0) {
            return MutationType.ADDITION
        }

        if (nextQuantity === 0) {
            return MutationType.REMOVAL
        }

        return MutationType.QUANTITY_CHANGE
    }
}
