/**
 * Format a price from a given `cents` integer.
 */
export function formatPriceInCents(cents: number, currency: string = '€') {
    if (cents % 100 === 0) {
        return `${currency + Math.round(cents / 100)},-`
    }

    return currency + (cents / 100).toFixed(2).replace('.', ',')
}

/**
 * Formats a price given in a `euro.cents` floating point number.
 */
export const formatPriceInEuros = (euros: number, currency: string = '€') => formatPriceInCents(
    euros * 100,
    currency,
)

/**
 * Formats a price given in a (round) `euro` integer. Suitable for cases where
 * no cents fraction is expected.
 */
export const formatPriceInEurosRound = (euros: number, currency: string = '€') => formatPriceInCents(
    Math.round(euros) * 100,
    currency,
)

// Taken from the client-library as-is. Should be cleaned up to use the other available functions.
export const formatPrice = (num: number): string => num.toLocaleString('nl', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})
