import { addClasses, hide, touchAll } from 'lambda-dom'
import { isFunction } from '../lib/base.lib'

export function initializeCommentsFieldToggle(onToggle?: () => void): void {
    touchAll([
        'button#comments-field-toggle',
        '#comments-field-container',
        'textarea#comments-field',
    ], (
        commentsFieldToggle,
        commentsFieldContainer,
        commentsField,
    ) => {
        commentsFieldToggle.addEventListener('click', () => {
            hide(commentsFieldToggle)
            addClasses('active')(commentsFieldContainer)
            commentsField?.focus()

            if (isFunction(onToggle)) {
                onToggle()
            }
        })
    })
}

export function initializeCouponCodeToggle(onToggle?: () => void): void {
    touchAll([
        'button#coupon-code-form-toggle',
        '#coupon-code-form-container',
        'input#coupon-code-field',
    ], (
        couponCodeFieldToggle,
        couponCodeFieldContainer,
        couponCodeField,
    ) => {
        couponCodeFieldToggle.addEventListener('click', () => {
            hide(couponCodeFieldToggle)
            addClasses('active')(couponCodeFieldContainer)
            couponCodeField.focus()

            if (isFunction(onToggle)) {
                onToggle()
            }
        })
    })
}
