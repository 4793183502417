export class CssVariableService {
    private readonly root: HTMLElement

    constructor() {
        this.root = document.querySelector(':root')!
    }

    public getVariable(name: string): string {
        const rootStyle = getComputedStyle(this.root)

        return rootStyle.getPropertyValue(name)
    }

    public setVariable(name: string, value: string) {
        this.root.style.setProperty(`--${name}`, value)
    }
}
