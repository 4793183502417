import type { DatasetElement } from '@app-types/common.types'
import noUiSlider, { API as noUiSliderAPI } from 'nouislider'
import { triggerChangeEvent } from '@app-lib/dom.lib'
import { parseDecimal } from '../../algolia/lib/route-state.lib'

export class SizeRangeSliderComponent {

    public readonly sliderInstance: noUiSliderAPI

    protected readonly minInitial: number
    protected readonly maxInitial: number
    protected readonly lowerBound: number
    protected readonly upperBound: number
    protected readonly disabled: number

    constructor(
        public readonly container: DatasetElement<{ min: string, max: string }>,
        public readonly minInput: HTMLInputElement,
        public readonly maxInput: HTMLInputElement,
    ) {
        this.minInitial = Math.floor(parseFloat(minInput.value))
        this.maxInitial = Math.ceil(parseFloat(maxInput.value))
        this.lowerBound = Math.floor(parseFloat(container.dataset.min))
        this.upperBound = Math.ceil(parseFloat(container.dataset.max))
        this.disabled = container.dataset.disabled === '0' ? 0 : 1

        // Force integer input values, even though in the template floats are used:
        minInput.value = String(this.minInitial)
        maxInput.value = String(this.maxInitial)

        this.sliderInstance = noUiSlider.create(container, {
            connect: true,
            step: 1,
            start: [
                this.minInitial,
                this.maxInitial,
            ],
            range: {
                min: this.lowerBound,
                max: this.upperBound,
            },
            tooltips: true,
            format: {
                to(value: number): string {
                    return Math.round(value).toString()
                },
                from(value: string): number {
                    const parsed = parseFloat(value)
                    return isNaN(parsed) ? 0 : Math.round(parsed)
                },
            },
        })

        if (this.disabled === 1) {
            this.sliderInstance.disable()
            this.minInput.disabled = true
            this.maxInput.disabled = true
        }

        this.sliderInstance.on('change', ([min, max]: [number, number]) => {
            const minPrevious = parseDecimal(minInput.value)
            const maxPrevious = parseDecimal(maxInput.value)

            minInput.value = String(min)
            maxInput.value = String(max)

            if (min !== minPrevious) {
                triggerChangeEvent(minInput)
            }

            if (max !== maxPrevious) {
                triggerChangeEvent(maxInput)
            }
        })
    }
}
