import { Observable, Subject } from 'rxjs'
import EventDelegation, { DelegationEvent, EventHandler } from '@jjwesterkamp/event-delegation'
import { isElement } from '../../vendor/lodash'

export class Modal {

    static create<T extends typeof Modal>(this: T, elementOrId: HTMLElement | string): InstanceType<T> {

        const element = typeof elementOrId === 'string'
            ? document.getElementById(elementOrId)
            : elementOrId

        if (! isElement(element)) {
            throw new Error(`Given argument of type "${typeof elementOrId}" with value ${elementOrId} is not a valid HTMLElement or ID thereof`)
        }

        return new this(element) as InstanceType<T>
    }

    private readonly closeButtonClicksSubject$: Subject<DelegationEvent<Element, MouseEvent, HTMLElement>>
    public readonly closeButtonClicks$: Observable<DelegationEvent<Element, MouseEvent, HTMLElement>>
    public readonly closes$: Subject<any>
    protected modalFooter: HTMLElement | null
    protected closeListener: EventHandler<HTMLElement>

    constructor(protected element: HTMLElement) {

        this.modalFooter = this.element.querySelector('.modal--footer')
        this.closes$ = new Subject()

        this.closeButtonClicksSubject$ = new Subject<DelegationEvent<Element, MouseEvent, HTMLElement>>()

        this.closeListener = EventDelegation
            .within(element)
            .events('click')
            .select('[data-close-modal]')
            .listen((e) => this.closeButtonClicksSubject$.next(e))

        this.closeButtonClicks$ = this.closeButtonClicksSubject$.asObservable()
        this.closeButtonClicks$.subscribe(() => this.hide())
    }

    public show(): this {
        if (this.isOpen()) {
            return this
        }

        this.element.classList.add('active')

        return this
    }

    /**
     * @return {Modal}
     */
    public hide(): this {
        if (this.isClosed()) {
            return this
        }

        this.element.classList.remove('active')

        this.closes$.next()
        return this
    }

    public destroy(): void {
        this.closeButtonClicksSubject$.complete()
        this.closes$.complete()
        this.closeListener.remove()
    }

    isClosed() {
        return ! this.isOpen()
    }

    isOpen() {
        return this.element.classList.contains('active')
    }
}
