import { getMeta } from 'lambda-dom'

declare global {
    const grecaptcha: any

    function recaptchaOnload(): void
}

export const recaptchaReady = new Promise<void>((resolve) => {
    window.recaptchaOnload = () => resolve()
})

export const RECAPTCHA_INVISIBLE_SITEKEY = getMeta('RECAPTCHA_INVISIBLE_SITEKEY')
export const RECAPTCHA_CHECKBOX_SITEKEY = getMeta('RECAPTCHA_CHECKBOX_SITEKEY')
