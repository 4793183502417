import type { Widget } from 'instantsearch.js'
import { connectToggleRefinement } from 'instantsearch.js/es/connectors'

import { FacetAttribute } from '../../algolia.types'

export interface ToggleWidgetOptions {
    container: HTMLElement,
    attribute: FacetAttribute,
}

export const createToggleWidget = (
    options: ToggleWidgetOptions,
): Widget => {
    return connectToggleRefinement((renderOptions, isFirstRender: boolean) => {
        const { value, refine } = renderOptions

        const toggle = options.container.querySelector<HTMLInputElement>('input')!

        if (isFirstRender) {
            options.container.addEventListener('change', (event) => {
                const eventTarget = event.target! as HTMLInputElement
                refine({ isRefined: ! eventTarget.checked })
            })
        }

        toggle.checked = value.isRefined
    })({
        attribute: options.attribute,
    })
}
