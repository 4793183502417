import {
    AddBuyableRequest,
    BuyableAction,
    IMutationReport,
    MutationFailure,
    MutationRequest,
    MutationSuccess,
    RemoveBuyableRequest,
    SetBuyableQuantityRequest,
} from '@app-services/shopping-list/shopping-list.types'

export function mutationSuccess<T extends BuyableAction>(requestType: T, report: IMutationReport): MutationSuccess<T> {
    return {
        status: 'success',
        report,
        requestType,
    }
}

export function mutationFailure<T extends BuyableAction>(requestType: T, message: string): MutationFailure<T> {
    return {
        status: 'error',
        message,
        requestType,
    }
}

// ------------------------------------------------------------------------------
//      Request discrimination
// ------------------------------------------------------------------------------
//
//      We cannot inline these assertions because the different request
//      types do not use literal values on the discriminatory property,
//      but enum values instead.

export const isAddBuyableRequest = (request: MutationRequest): request is AddBuyableRequest => (
    request.type === BuyableAction.ADD
)

export const isRemoveBuyableRequest = (request: MutationRequest): request is RemoveBuyableRequest => (
    request.type === BuyableAction.REMOVE
)

export const isSetBuyableQuantityRequest = (request: MutationRequest): request is SetBuyableQuantityRequest => (
    request.type === BuyableAction.SET_QUANTITY
)
