import { isNil } from 'ramda'
import { Reveal } from 'foundation-sites'

import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { ENV } from '@app-bootstrap/environment.bootstrap'

import type { DeliveryPromiseEnrichment } from './product-list-widget.lib'
import { formatPriceInCents } from '@app-lib/prices.lib'

import template from './product-list-widget.template'

import { shoppingList } from '@app-services/shopping-list/shopping-list.service'
import { addFavorite, removeFavorite } from '@app-services/favorites/favorites.service'
import { Tracker } from '@app-services/tracker/tracker.service'

import type { EventData, VariantHit } from '../../algolia.types'
import { getVariantData } from '@app-catalog/api/catalog-api.index'
import { changeVariantUrlToLocal } from '@app-bootstrap/convert-to-local-urls.bootstrap'

type EnrichedVariantHit =
    & VariantHit
    & DeliveryPromiseEnrichment

const aa = require('search-insights')

function getDefaultIndex() {
    const container = document.querySelector('body')

    return container?.getAttribute('data-default-algolia-index')
}

/* eslint no-underscore-dangle: ["error", { "allow": ["__queryID", "__position"] }] */
export default {
    template,
    data: {
        loading: true,
        favorites: [] as string[],
        variants: [] as (VariantHit | EnrichedVariantHit)[],
        index: '' as string,
        searchQuery: '' as string,
    },

    computed: {
        statefulVariants(): (EnrichedVariantHit & { isFavorite: boolean })[] {
            return this.variants.map((variant: EnrichedVariantHit) =>
                Object.assign(variant,
                    {
                        isFavorite: this.favorites.includes(`variant:${variant.id}`),
                        url: (ENV.isDevelopment() ? changeVariantUrlToLocal(variant.url) : variant.url),
                    },
                ),
            )
        },
    },

    methods: {
        async addFavorite(variant: EnrichedVariantHit): Promise<void> {
            await addFavorite(`variant:${variant.id}`)
        },

        async removeFavorite(variant: EnrichedVariantHit): Promise<void> {
            await removeFavorite(`variant:${variant.id}`)
        },

        async productWasClicked(variant: VariantHit): Promise<void> {
            Tracker.pushGTMDataWithEcommerceInit(await getVariantData(variant.id))

            this.storeAlgoliaEventForProductPage(variant)

            if (this.searchQuery === '') {
                this.productViewed(
                    variant,
                    'clickedObjectIDs',
                    'ProductAddedToCart',
                )
                return
            }

            this.productViewed(
                variant,
                'clickedObjectIDsAfterSearch',
                'ProductAddedToCartAfterSearch',
            )
        },

        productWasAddedToCart(product: VariantHit) {
            if (this.searchQuery === '') {
                this.productAddToCart(
                    product,
                    'convertedObjectIDs',
                    'ProductAddedToCart',
                    this.index,
                )

                if (this.index !== getDefaultIndex()) {
                    this.productAddToCart(
                        product,
                        'convertedObjectIDs',
                        'ProductAddedToCart',
                        getDefaultIndex(),
                    )
                }
            }

            this.productAddToCart(
                product,
                'convertedObjectIDsAfterSearch',
                'ProductAddedToCartAfterSearch',
                this.index,
            )

            if (this.index !== getDefaultIndex()) {
                this.productAddToCart(
                    product,
                    'convertedObjectIDsAfterSearch',
                    'ProductAddedToCartAfterSearch',
                    getDefaultIndex(),
                )
            }
        },

        productViewed(product: VariantHit, event: string, eventName: string): void {
            aa(event, {
                eventName,
                index: this.index,
                queryID: product.__queryID,
                objectIDs: [product.objectID],
                positions: [product.__position],
            })
        },

        productAddToCart(product: VariantHit, event: string, eventName: string, index: string): void {
            aa(event, {
                eventName,
                index,
                queryID: product.__queryID,
                objectIDs: [product.objectID],
            })
        },

        formatPrice(cents: number) {
            return formatPriceInCents(cents, '')
        },

        backgroundImage(badge?: VariantHit['product_badge']): object {
            if (isNil(badge)) {
                return {}
            }

            return {
                backgroundImage: `url("${badge.image_url}")`,
                backgroundColor: badge.background || null,
            }
        },

        async addBuyableToCart(variant: VariantHit, quantity: number): Promise<void> {
            const result = await shoppingList.addBuyable(`variant:${variant.id}`, quantity)

            if (result.status === 'success') {
                this.productWasAddedToCart(variant)

                this.sendAlgoliaEvent(variant)
            }
        },

        sendAlgoliaEvent(variant: VariantHit): void {
            const eventName = 'ProductPurchased'

            axios.post('/algolia/save-event', { event: this.makeAlgoliaEvent(variant, eventName) })
                .then(() => {
                    // Response not needed
                })
                .catch((error) => {
                    bugsnagClient.notify(error)
                })
        },

        makeAlgoliaEvent(variant: VariantHit, eventName: string): EventData {
            return {
                eventName,
                eventType: 'conversion',
                index: this.index,
                queryID: variant.__queryID,
                objectID: variant.objectID,
            }
        },

        storeAlgoliaEventForProductPage(variant: VariantHit): void {
            const eventName = 'ProductAddedToCart'
            const eventData = this.makeAlgoliaEvent(variant, eventName)

            localStorage?.setItem('algoliaEventData', JSON.stringify(eventData))
        },

        requestNotifyMeModal(productSlug: string, slug: string): void {
            const modal = new Reveal($('#notify-me-modal'))
            modal.open()

            $.ajax(`/product/${productSlug}/${slug}/notify-me-of-stock`)
                .done(function (resp) {
                    // @ts-ignore
                    modal.$element[0].innerHTML = resp
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    const error = 'Notify me of stock modal request failed: '
                        + textStatus + ' ' + errorThrown + ' ' + jqXHR
                    console.error('Error: ', error)
                    bugsnagClient.notify(error)
                })
        },
    },
}
