import { isNil, mergeRight, toPairs } from 'ramda'
import { API as noUiSliderAPI } from 'nouislider'
import type { Router, Widget } from 'instantsearch.js'
import { clearRefinements, stats } from 'instantsearch.js/es/widgets'
import history from 'instantsearch.js/es/lib/routers/history'

import {
    parseDecimal,
    ParsedRouteState,
    parseFilterValue,
    RawNumericRange,
    RawRouteState,
    SerializedIdList,
} from './lib/route-state.lib'

import {
    FacetAttribute,
    FacetContainers,
    FacetOperationMode,
    FacetSetupEntry,
    InstantSearchContext,
    InstantSearchSetup,
    LocalFacetType,
    RangeFilter,
    SortIndex,
    filtersConfig,
} from './algolia.types'

import { createAvailabilityLimitWidget } from './connectors/availability-limit/availability-limit-widget.index'
import { createRefinementListWidget } from './connectors/refinement-list/refinement-list-widget.index'
import { createPriceRangeWidget } from './connectors/price-range/price-range-widget.index'
import { createSizeRangeWidget } from './connectors/size-range/size-range-widget.index'
import { createToggleWidget } from './connectors/toggle/toggle-widget.index'

export const createResultsCountWidget = (container: HTMLElement) => stats({
    container,
    templates: {
        text(data) {
            if (data.hasManyResults) {
                return `${data.nbHits} producten`
            }

            if (data.hasOneResult) {
                return '1 product'
            }

            return 'Geen resultaten'
        },
    },
})

/**
 *
 */
export function createFacetWidget(
    { localType, remoteType, operationMode }: FacetSetupEntry,
    context: InstantSearchContext,
    priceSlider?: noUiSliderAPI,
    sizeWidthSlider?: noUiSliderAPI,
    sizeHeightSlider?: noUiSliderAPI,
    sizeDepthSlider?: noUiSliderAPI,
    sizeSeatHeightSlider?: noUiSliderAPI,
    sizeDiameterSlider?: noUiSliderAPI,
) {
    switch (operationMode) {
        case FacetOperationMode.ALL_PASS_CHECKBOX:
        case FacetOperationMode.ANY_PASS_CHECKBOX: {
            return (context.facetContainers[remoteType] ?? []).map((c) => createRefinementListWidget(
                c,
                remoteType,
                operationMode,
            ))
        }
        case FacetOperationMode.PRICE_RANGE: {
            const priceRanges = []

            if (priceSlider) {
                priceRanges.push([
                    createPriceRangeWidget({
                        slider: priceSlider,
                        attribute: remoteType,
                    }),
                ])
            }

            return priceRanges
        }
        case FacetOperationMode.SIZE_RANGE: {
            const sizeFiltersRanges = []

            if (remoteType === FacetAttribute.WIDTH && ! isNil(sizeWidthSlider)) {
                sizeFiltersRanges.push({
                    slider: sizeWidthSlider!,
                    attribute: remoteType,
                    minInput: document.querySelector(filtersConfig.width.minInput)!,
                    maxInput: document.querySelector(filtersConfig.width.maxInput)!,
                })
            }

            if (remoteType === FacetAttribute.HEIGHT && ! isNil(sizeHeightSlider)) {
                sizeFiltersRanges.push({
                    slider: sizeHeightSlider!,
                    attribute: remoteType,
                    minInput: document.querySelector(filtersConfig.height.minInput)!,
                    maxInput: document.querySelector(filtersConfig.height.maxInput)!,
                })
            }

            if (remoteType === FacetAttribute.DEPTH && ! isNil(sizeDepthSlider)) {
                sizeFiltersRanges.push({
                    slider: sizeDepthSlider!,
                    attribute: remoteType,
                    minInput: document.querySelector(filtersConfig.depth.minInput)!,
                    maxInput: document.querySelector(filtersConfig.depth.maxInput)!,
                })
            }

            if (remoteType === FacetAttribute.SEAT_HEIGHT && ! isNil(sizeSeatHeightSlider)) {
                sizeFiltersRanges.push({
                    slider: sizeSeatHeightSlider!,
                    attribute: remoteType,
                    minInput: document.querySelector(filtersConfig.seatHeight.minInput)!,
                    maxInput: document.querySelector(filtersConfig.seatHeight.maxInput)!,
                })
            }

            if (remoteType === FacetAttribute.DIAMETER && ! isNil(sizeDiameterSlider)) {
                sizeFiltersRanges.push({
                    slider: sizeDiameterSlider!,
                    attribute: remoteType,
                    minInput: document.querySelector(filtersConfig.diameter.minInput)!,
                    maxInput: document.querySelector(filtersConfig.diameter.maxInput)!,
                })
            }

            return sizeFiltersRanges.map((c) => createSizeRangeWidget(
                {
                    slider: c.slider!,
                    attribute: c.attribute,
                },
                c.minInput as HTMLInputElement,
                c.maxInput as HTMLInputElement,
            ))
        }
        case FacetOperationMode.TOGGLE: {
            return (context.facetContainers[remoteType] ?? []).map((c) => createToggleWidget({
                container: c,
                attribute: remoteType,
            }))
        }
        case FacetOperationMode.AVAILABILITY_LIMIT: {
            return (context.facetContainers[remoteType] ?? []).map((c) => createAvailabilityLimitWidget({
                container: c,
                localFacetType: localType,
                remoteFacetType: remoteType,
            }))
        }
    }
}

export const createClearRefinementsWidget = (container: HTMLElement): Widget => clearRefinements({
    container,
    templates: {
        resetLabel: 'Filters wissen',
    },
    cssClasses: {
        button: ['button', 'dark', 'small', 'button--full-width'],
    },
})

export const createClearRefinementsLinkWidget = (container: HTMLElement): Widget => clearRefinements({
    container,
    templates: {
        resetLabel: 'Filters wissen',
    },
    cssClasses: {
        button: ['small', 'plf--clear-filters-link'],
    },
})

export const createHistoryRouter = (): Router => history({

    writeDelay: 300,

    parseURL({ qsModule, location }): ParsedRouteState {
        const rawParse = qsModule.parse(location.search, { ignoreQueryPrefix: true }) as RawRouteState
        const routeState: ParsedRouteState = {}

        if (rawParse.page) {
            routeState.page = parseInt(rawParse.page, 10)
        }

        if (rawParse.sorting) {
            routeState.sorting = rawParse.sorting
        }

        if (rawParse.filter) {
            for (const [localType, value] of toPairs<SerializedIdList | RawNumericRange>(rawParse.filter)) {
                routeState.filter = routeState.filter || {}
                routeState.filter[localType as LocalFacetType] = parseFilterValue(value)
            }
        }

        return routeState
    },

    createURL({ qsModule, routeState, location }): string {
        const queryObject = qsModule.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })

        if (! routeState.filter) {
            delete queryObject.filter
        }

        return location.origin + location.pathname + qsModule.stringify(
            mergeRight(queryObject, routeState),
            {
                addQueryPrefix: true,
                encode: false,
                arrayFormat: 'brackets',
            },
        )
    },
})

export const formatPriceRange = ({ min, max }: RangeFilter): string => `${min * 100}:${max * 100}`

export const formatSizeRange = ({ min, max }: RangeFilter): string => `${min}:${max}`

/**
 * Todo: sometimes instantsearch creates a string such as
 *       - `'min,minFilter:max,maxFilter'`
 *       - `'min:max,maxFilter'`
 *       There's no good way to know what segment in the string is the actual filter :/
 *       This is undocumented, and causes hard to track bugs.
 */
export const parseRange = (colonNotation: string | undefined, priceRange?: boolean): RangeFilter | undefined => {

    if (isNil(colonNotation)) {
        return undefined
    }

    const regex = /^(?:\d+,)?(\d+):(?:\d+,)?(\d+)$/

    const [, min, max] = colonNotation.match(regex) || []

    return {
        min: priceRange ? parseDecimal(min) / 100 : parseDecimal(min),
        max: priceRange ? parseDecimal(max) / 100 : parseDecimal(max),
    }
}

export const getDefaultSorting = (setup: InstantSearchSetup): SortIndex | undefined => (
    setup.sortIndices.find((sortIndex) => sortIndex.isDefault)
)

export const findSortingByIndexName = (setup: InstantSearchSetup, indexName: string): SortIndex | undefined => (
    setup.sortIndices.find((sortIndex) => sortIndex.value === indexName)
)

export const findSortingBySlug = (setup: InstantSearchSetup, slug: string | undefined): SortIndex | undefined => (
    setup.sortIndices.find((sortIndex) => sortIndex.slug === slug)
)

/**
 * Finds all facet container elements for given instantsearch setup, based on the convention that all
 * container elements have an ID attribute that is `"algolia-facet-<localType>"`, where `localType` is
 * a value specified in {@link LocalFacetType the local facet types enum}.
 */
export const findFacetContainers = ({ facetSetup }: InstantSearchSetup): FacetContainers => facetSetup.reduce(
    (acc, { localType, remoteType }) => ({
        ...acc,
        [remoteType]: [
            document.getElementById(`algolia-facet-${localType}`),
            document.getElementById(`algolia-facet-${localType}-mobile`),
        ].filter((c) => !! c),
    }),
    {},
)
