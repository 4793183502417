import { AjaxError } from '@app-errors'

export class HttpClient {

    public async get(
        url: string,
        params: Record<string, any> = {},
        headers: HeadersInit = {},
    ): Promise<any> {
        const queryString = this.buildQueryString(params)
        const requestUrl = queryString ? `${url}?${queryString}` : url

        const requestOptions: RequestInit = {
            method: 'GET',
            headers,
        }

        const response = await fetch(requestUrl, requestOptions)
        return this.handleResponse(response)
    }

    public async post(
        url: string,
        params: Record<string, any> = {},
        headers: HeadersInit = {},
        body: FormData | Record<string, any> | null = null,
    ): Promise<any> {
        const queryString = this.buildQueryString(params)
        const requestUrl = queryString ? `${url}?${queryString}` : url

        const requestOptions: RequestInit = {
            method: 'POST',
            headers,
            body: body instanceof FormData ? body : JSON.stringify(body),
        }

        const response = await fetch(requestUrl, requestOptions)
        return this.handleResponse(response)
    }

    private buildQueryString(params: Record<string, any>): string {
        const queryString = new URLSearchParams(params)
        return queryString.toString()
    }

    private async handleResponse(response: Response): Promise<any> {
        if (! response.ok) {
            const contentType = response.headers.get('Content-Type')
            let responseBody
            try {
                responseBody = contentType && contentType.includes('application/json')
                    ? await response.json()
                    : await response.text()
            } catch (error) {
                responseBody = 'Failed to read response body.'
            }

            throw new AjaxError(`Request failed with status ${response.status}`, response.status, responseBody)
        }

        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.includes('application/json')) {
            return response.json()
        }

        // Handle non-JSON responses (e.g., text, HTML)
        return response.text()
    }
}
