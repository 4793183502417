import { Swiper } from 'swiper'
import { SwiperOptions } from 'swiper/types/swiper-options'

function handleSlideChange(swiperInstance: Swiper): void {
    swiperInstance.pagination?.render()
    swiperInstance.pagination?.update()

    swiperInstance.el?.classList.toggle('is-beginning', swiperInstance.isBeginning)
    swiperInstance.el?.classList.toggle('is-end', swiperInstance.isEnd)
}

const DEFAULT_SWIPER_OPTIONS: SwiperOptions = {
    // Add default options if any
    on: {
        slideChange: function (swiper: Swiper) {
            handleSlideChange(swiper)
        },
    },
}

export class BasicSwiper {
    public swiper: Swiper

    constructor(
        protected element: HTMLElement,
        protected swiperOptions: SwiperOptions = {},
    ) {
        this.initializeSwiper()
    }

    protected initializeSwiper(): void {
        this.swiper = new Swiper(this.element, { ...DEFAULT_SWIPER_OPTIONS, ...this.swiperOptions })
    }

    /**
     * Public method to update the Swiper instance.
     * Destroys the current instance and reinitializes it.
     */
    public update(): void {
        this.swiper.destroy(true, true)
        this.initializeSwiper()
    }
}