import axios from 'axios'
import { isEmpty, reject } from 'ramda'
import type {
    CarrierApiResponse,
    CarriersRecord,
    DeliveryPromiseRecord,
    DeliveryPromiseResponse,
    VariantId,
} from './catalog-api.types'
import { endpoints } from './catalog-api.config'
import { endpoints as GTMEndpoints } from '../../config/backend.config'

// ------------------------------------------------------------------------------
//      Variant impressions
// ------------------------------------------------------------------------------

// Todo: cache impressions (needs proper typing first)
export const getVariantImpressions = async (ids: VariantId[], listName: string, allIds: VariantId[]): Promise<any> => {
    const { data: response } = await axios.get<any>(endpoints.variantImpressions, {
        params: { variants: ids, listName, allIds },
    })

    return response.data
}

export const getVariantData = async (id: VariantId): Promise<any> => {
    const { data: response } = await axios.get<any>(GTMEndpoints.product.trackingData, {
        params: { variantId: id },
    })

    return response
}

// ------------------------------------------------------------------------------
//      Carriers
// ------------------------------------------------------------------------------

export async function getCarriers(): Promise<CarriersRecord> {
    const { data: response } = await axios.get<CarrierApiResponse>(endpoints.carriers)
    return response.data
}

// ------------------------------------------------------------------------------
//      Delivery promises
// ------------------------------------------------------------------------------

const cachedDeliveryPromises: DeliveryPromiseRecord = {}

export async function getDeliveryPromises(variantIds: VariantId[]): Promise<DeliveryPromiseRecord> {
    const nonCachedIds = reject((id: number) => cachedDeliveryPromises.hasOwnProperty(id), variantIds)

    if (! isEmpty(nonCachedIds)) {
        const { data: response } = await axios.get<DeliveryPromiseResponse>(endpoints.deliveryPromises, {
            params: { variants: nonCachedIds },
        })

        for (const deliveryPromise of response.data) {
            cachedDeliveryPromises[deliveryPromise.id] = deliveryPromise.content
        }
    }

    return variantIds.reduce((record, id) => ({ ...record, [id]: cachedDeliveryPromises[id] }), {})
}
