import { BehaviorSubject } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { toggleClasses } from 'lambda-dom'
import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { scrolls$ } from '@app-bootstrap/scroll-tracking.bootstrap'
import { querySearch } from '../search/search.factory'

export class SiteHeader {

    static query(selector: string): SiteHeader {
        const element = document.querySelector<HTMLElement>(selector)

        if (element === null) {
            throw new Error(`Given selector '${selector}' did not match an HTML element`)
        }

        return new this(element)
    }

    // ------------------------------------------------------------------------------
    //      State
    // ------------------------------------------------------------------------------

    private topElement = this.container.querySelector<HTMLElement>('.site-header--top')!
    private middleInnerElement = this.container.querySelector<HTMLElement>('.site-header--middle--inner')!

    private isSticky$ = new BehaviorSubject(false)

    constructor(private container: HTMLElement) {

        scrolls$.pipe(startWith(null)).subscribe(() => this.updateSticky())

        try {
            querySearch('.global-search-container', container)
        } catch (e) {
            bugsnagClient.notify(e)
        }
    }

    // ------------------------------------------------------------------------------
    //      Runtime
    // ------------------------------------------------------------------------------

    /**
     * Updates the sticky state of the middle segment of the header.
     */
    protected updateSticky(): void {
        const offset = window.pageYOffset
        const distance = this.middleInnerElement.offsetTop - offset

        if ((distance <= 0) && ! this.isSticky$.getValue()) {
            this.middleInnerElement.classList.add('sticky')
            this.isSticky$.next(true)
        } else if (this.isSticky$.getValue() && (offset <= this.topElement.offsetHeight)) {
            this.middleInnerElement.classList.remove('sticky')
            this.isSticky$.next(false)
        }
    }

    public defeatBoxShadow(bool: boolean): void {
        toggleClasses('no-shadow')(this.middleInnerElement, bool)
    }

    public getHeight(): number {
        return this.container.getBoundingClientRect().height
    }
}
