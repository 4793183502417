import { always, ifElse, isEmpty, pipe, pluck, prop } from 'ramda'
import { deferFramesP } from 'lambda-dom'
import { SearchResults } from 'algoliasearch-helper'
import { connectHits } from 'instantsearch.js/es/connectors'
import Vue from 'vue'

import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'

import type { VariantHit } from '../../algolia.types'
import { AvailabilityLevel } from '../../algolia.types'

import { enrichWithDeliveryPromises } from './product-list-widget.lib'
import { getDeliveryPromises, getVariantImpressions } from '@app-catalog/api/catalog-api.index'

import { Tracker } from '@app-services/tracker/tracker.service'
import { favoritesContent$ } from '@app-services/favorites/favorites.service'

import vueConfig from './product-list-widget.vue'

interface CustomHitsOptions {
    container: HTMLElement
}

export function getListNameFromParams(params: string): string {
    const searchParams = new URLSearchParams(params)

    const regex = /product_categories:"([^"]*)"/

    let queryFilters = ''

    if (searchParams.has('filters')) {
        queryFilters = searchParams.get('filters') ?? ''
    }

    const listNameResult = regex.exec(queryFilters)

    return ifElse(
        isEmpty,
        always(''),
        prop('1'),
    )(listNameResult) as string
}

export function createProductListWidget({ container }: CustomHitsOptions) {

    const vue = new Vue(vueConfig)
    let mounted = false

    favoritesContent$.subscribe((contents) => { vue.favorites = contents })

    return connectHits<CustomHitsOptions>(async (renderOptions, isFirstRender) => {
        const hits = renderOptions.hits as unknown as VariantHit[]
        const hitIds = pluck('id', hits)

        if (mounted) {
            vue.loading = true
        }

        vue.index = renderOptions.results?.index ?? ''
        vue.searchQuery = renderOptions.results?.query ?? ''
        vue.variants = hits

        // Await one animation frame to force the list fade, even when
        // all discounts are cached.
        await deferFramesP(1)

        if (! isFirstRender && ! mounted) {
            vue.$mount(container)
            mounted = true
        }

        try {
            const inStockHitIds = hits
                .filter((hit) => hit.availability_level >= AvailabilityLevel.IMMEDIATELY_AVAILABLE)
                .map((hit) => hit.id)

            // Get enrichment source data (in parallel):
            const deliveryPromises = await getDeliveryPromises(inStockHitIds)

            vue.variants = pipe(
                enrichWithDeliveryPromises(deliveryPromises),
            )(hits)
        } catch (error) {
            vue.variants = hits
            bugsnagClient.notify(error)
        }

        // Await another couple of frames to prevent the old content to be visible
        // in a flash after the new data has been set.
        await deferFramesP(5)

        vue.loading = false

        if (! isEmpty(hitIds)) {
            try {
                // @todo Remove type extension in the future once params are included in algolia package
                const results = renderOptions.results as SearchResults & { params: string }
                const { params } = results
                const listName = getListNameFromParams(params)
                const chunkSize = 20
                const promises = []

                for (let i = 0; i < hitIds.length; i += chunkSize) {
                    const chunk = hitIds.slice(i, i + chunkSize)
                    promises.push(getVariantImpressions(chunk, listName, hitIds)
                        .then((data) => Tracker.pushGTMDataWithEcommerceInit(data)))
                }

                await Promise.all(promises)
            } catch (error) {
                bugsnagClient.notify(error)
            }
        }
    })({ container })
}
