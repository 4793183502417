import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { AddressLookup } from '../../checkout/checkout.types'
import { lookupAddress } from '../../checkout/services/backend.service'
import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'

export class AddressLookupService {
    constructor(
        private readonly formElement: HTMLFormElement,
    ) {
        this.initAddressLookup()
    }

    public initAddressLookup(): void {
        if (this.formElement === null) {
            return
        }

        const postalCodeField = this.formElement.querySelector<HTMLInputElement>('[name="postal_code"]')!
        const houseNumberField = this.formElement.querySelector<HTMLInputElement>('[name="house_number"]')!
        const streetField = this.formElement.querySelector<HTMLInputElement>('[name="street_name"]')!
        const cityField = this.formElement.querySelector<HTMLInputElement>('[name="municipality"]')!

        const lookupSubject = new Subject<void>()

        lookupSubject.pipe(debounceTime(1500)).subscribe(() => {
            const postalCode = postalCodeField.value
            const houseNumber = houseNumberField.value

            if (! postalCode && ! houseNumber) return

            void this.getAddressByPostalCodeAndHouseNumber(
                {
                    postal_code: postalCode,
                    house_number: houseNumber,
                },
                {
                    street: streetField,
                    city: cityField,
                },
            )
        })

        const prepareDataForAddressLookup = () => lookupSubject.next()

        postalCodeField.addEventListener('input', prepareDataForAddressLookup)
        houseNumberField.addEventListener('input', prepareDataForAddressLookup)
    }

    public async getAddressByPostalCodeAndHouseNumber(
        address: AddressLookup,
        fillFields?: {
            street: HTMLInputElement
            city: HTMLInputElement
        },
    ): Promise<void> {
        try {
            const { street, city } = await lookupAddress(address)

            if (fillFields) {
                fillFields.street.value = street
                fillFields.city.value = city
            }
        } catch (error) {
            console.error('Error looking up address:', error)
            bugsnagClient.notify(error)
        }
    }
}
