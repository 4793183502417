import { any, isNil } from 'ramda'
import { Search } from './search.component'

/**
 * Constructs a Search component for the element matching given selector, found within
 * given scope. If no scope is provided, a global search for the element will be done.
 *
 * @throws {Error} - If the element itself, or any of its required descendants cannot be found.
 */
export const querySearch = (selector: string, scope: ParentNode = document): Search => {

    const element = scope.querySelector<HTMLElement>(selector)
    const searchContainer = element?.querySelector<HTMLElement>('.global-search')
    const searchField = searchContainer?.querySelector<HTMLInputElement>('.global-search--field')
    const searchResultsContainer = element?.querySelector<HTMLElement>('.global-search--results')

    if (any(isNil, [element, searchContainer, searchField, searchResultsContainer])) {
        throw new Error(`Did not find required elements for Search component${JSON.stringify({
            element, searchContainer, searchField, searchResultsContainer,
        })}`)
    }

    return new Search(
        searchContainer !,
        searchField !,
        searchResultsContainer !,
    )
}
