import type { DatasetElement } from '@app-types/common.types'
import { Subject } from 'rxjs'
import EventDelegation from '@jjwesterkamp/event-delegation'
import { cond, T as OTHERWISE } from 'ramda'
import { map, switchMap } from 'rxjs/operators'
import { queryAll, queryAllWithin, showIf, toggleClasses } from 'lambda-dom'
import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { QuantityControl } from '@app-components/quantity-control/quantity-control.component'
import {
    isAddBuyableRequest,
    isRemoveBuyableRequest,
    isSetBuyableQuantityRequest,
    mutationFailure,
    mutationSuccess,
} from '@app-services/shopping-list/shopping-list.lib'
import {
    AddBuyableRequest,
    BuyableAction,
    BuyableForm, IMutationReport,
    IShoppingListBackend,
    MutationRequest,
    MutationResult,
    MutationSuccess,
    RemoveBuyableRequest,
    SetBuyableQuantityRequest,
} from '@app-services/shopping-list/shopping-list.types'
import { CartMutationFormSubmission } from './CartMutationFormSubmission'

export class ShoppingList {
    private readonly mutationsSubject$ = new Subject<MutationSuccess>()
    public readonly mutations$ = this.mutationsSubject$.asObservable()

    constructor(private backend: IShoppingListBackend) {
        EventDelegation
            .global()
            .events('submit')
            .select<BuyableForm>('form[data-buyable-action]')
            .listen((event) => this.handleFormSubmission(CartMutationFormSubmission.create(
                event.delegator,
                event,
            )))

        this.queryQuantityControls()
    }

    // ------------------------------------------------------------------------------
    //      Mutation methods
    // ------------------------------------------------------------------------------

    public async addBuyable(identifier: string, quantity: number = 1): Promise<MutationResult<BuyableAction.ADD>> {
        return this.performMutation({
            type: BuyableAction.ADD,
            identifier,
            quantity,
        })
    }

    // eslint-disable-next-line max-len
    public setBuyableQuantity(identifier: string, quantity: number): Promise<MutationResult<BuyableAction.SET_QUANTITY>> {
        return this.performMutation({
            type: BuyableAction.SET_QUANTITY,
            identifier,
            quantity,
        })
    }

    public removeBuyable(identifier: string): Promise<MutationResult<BuyableAction.REMOVE>> {
        return this.performMutation({
            type: BuyableAction.REMOVE,
            identifier,
        })
    }

    // ------------------------------------------------------------------------------
    //      Content updates
    // ------------------------------------------------------------------------------

    /**
     * Updates the cart views. (Legacy)
     */
    protected async updateViews(): Promise<void> {
        await Promise.all(
            queryAll<DatasetElement<{ ajaxCartView: string }>>('[data-ajax-cart-view]').map(async (view) => {
                const { data: markup } = await axios.get(view.dataset.ajaxCartView)
                view.innerHTML = markup
                this.queryQuantityControls(view)
            }),
        )
    }

    protected queryQuantityControls(scope: HTMLElement = document.body): void {
        const quantityControls = queryAllWithin(scope)<HTMLElement>('[data-buyable-quantity-control]')

        for (const container of quantityControls) {
            const control = new QuantityControl<BuyableForm>({
                container,
                numberInputSelector: '[name="buyable[quantity]"]',
                stepUpButtonSelector: '[data-action="increase-quantity"]',
                stepDownButtonSelector: '[data-action="decrease-quantity"]',
            })

            control.values$.pipe(
                map(() => CartMutationFormSubmission.create(
                    control.getForm()!,
                )),
                switchMap((submission) => this.setBuyableQuantity(
                    submission.buyableIdentifier(),
                    submission.buyableQuantity()!,
                )),
            )
                .subscribe()
        }
    }

    /**
     * Updates cart counter contents / visibility (Legacy)
     */
    protected async updateCounters(): Promise<void> {
        const { nominalCount } = await this.backend.getCounts()

        queryAll<HTMLElement>('[data-cart-state="item-count"]').forEach((el) => {
            el.innerText = String(nominalCount)
            toggleClasses('hidden')(el, nominalCount <= 0)
        })

        queryAll<HTMLElement>('[data-show="cart-not-empty"]').forEach(showIf(nominalCount > 0))
        queryAll<HTMLElement>('[data-show="cart-empty"]').forEach(showIf(nominalCount <= 0))
    }

    // ------------------------------------------------------------------------------
    //      Runtime
    // ------------------------------------------------------------------------------

    protected async performMutation<T extends MutationRequest>(request: T): Promise<MutationResult<T['type']>> {

        try {
            const report = await cond<[MutationRequest], Promise<IMutationReport>>([
                [
                    isAddBuyableRequest,
                    (r: AddBuyableRequest) => this.backend.addBuyable(r.identifier, r.quantity)
                        .then((result) => {
                            return result
                        }),
                ],
                [
                    isRemoveBuyableRequest,
                    (r: RemoveBuyableRequest) => this.backend.removeBuyable(r.identifier),
                ],
                [
                    isSetBuyableQuantityRequest,
                    (r: SetBuyableQuantityRequest) => this.backend.setBuyableQuantity(r.identifier, r.quantity),
                ],
                [
                    OTHERWISE,
                    () => { throw new Error(`Invalid request type '${request.type ?? '[NONE]'}'`) },
                ],
            ])(request)

            // Try..catch?
            await Promise.all([
                this.updateViews(),
                this.updateCounters(),
            ])

            const mutationSuccessData = mutationSuccess(request.type, report)
            this.mutationsSubject$.next(mutationSuccessData)
            return mutationSuccessData
        } catch (e) {
            const failure = mutationFailure(request.type, e.message)
            bugsnagClient.notify({
                name: 'ShoppingListError',
                message: failure.message,
            })
            return failure
        }
    }

    protected handleFormSubmission(submission: CartMutationFormSubmission): Promise<MutationResult> {
        submission.disableServiceButton('button[data-service-identifier="add-sofa-placement-service"]')

        return this.performMutation<any>({
            type: submission.buyableAction(),
            identifier: submission.buyableIdentifier(),
            quantity: submission.buyableQuantity(),
        })
    }
}
