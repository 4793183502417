import bugsnag, { Client as BugsnagClient } from '@bugsnag/js'
import { getMeta } from 'lambda-dom'
import { defaultTo } from 'ramda'
import { Environment } from '@app-types/common.types'

const BUGSNAG_API_KEY = process.env.BUGSNAG_FRONTEND_API_KEY!

const dummyBugsnagClient = { notify: (...args) => console.warn('Bugsnag dummy got error:', args) } as BugsnagClient

export const bugsnagClient: BugsnagClient = BUGSNAG_API_KEY
    ? bugsnag.createClient({
        apiKey: BUGSNAG_API_KEY,
        releaseStage: defaultTo('client-env-unknown', getMeta('environment')),
        logger: null,
        enabledReleaseStages: [
            'client-env-unknown',
            Environment.ACCEPTANCE,
            Environment.PRODUCTION,
        ],
    })
    : dummyBugsnagClient
