export default (container: HTMLElement) => ({
    el: container,
    template: `
        <ul :style="style" class="pagination">
            <li :class="{disabled: isFirstPage}">
                <span v-if="isFirstPage">&lsaquo;</span>
                <a v-else @click="goToPage(currentPage - 1)" rel="prev">&lsaquo;</a>
            </li>
            <li class="display">{{currentPage}} van {{totalPages}}</li>
            <li :class="{disabled: isLastPage}">
                <span v-if="isLastPage">&rsaquo;</span>
                <a v-else @click="goToPage(currentPage + 1)" rel="next">&rsaquo;</a>
            </li>
        </ul>
    `,

    data() {
        return {
            isFirstPage: true,
            isLastPage: true,
            totalPages: 0,
            currentPage: 0,
        }
    },

    computed: {
        style() {
            return {
                visibility: this.totalPages === 0 ? 'hidden' : 'visible',
            }
        },
    },

    methods: {
        goToPage(page: number) {
            this.$emit('setPage', page)
        },
    },
})
