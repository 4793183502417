import Vue from 'vue'
import { fromEvent, interval, Observable } from 'rxjs'
import { hide, show } from 'lambda-dom'
import { map, takeUntil, takeWhile } from 'rxjs/operators'
import { mergeRight } from 'ramda'
import vueConfig from './counter'

const defaultOptions = {
    revealDuration: 4000,
}

export class PasswordField {

    public readonly options: any
    public readonly revealButton: HTMLButtonElement
    public readonly counterContainer: HTMLElement
    public readonly input: HTMLInputElement
    public readonly revealRequests$: Observable<Event>
    public readonly hideRequests$: Observable<Event>
    public readonly vueInstance: Vue

    constructor(
        public readonly container: HTMLElement,
        options = {},
    ) {

        this.options = mergeRight(defaultOptions, options)

        this.container = container
        this.input = container.querySelector<HTMLInputElement>('input[type="password"]')!
        this.revealButton = container.querySelector<HTMLButtonElement>('.password-field-container--button')!

        const counterContainer = container.querySelector<HTMLElement>('.password-field-container--counter')!

        this.vueInstance = new Vue(vueConfig(counterContainer.id))
        this.counterContainer = this.vueInstance.$el as HTMLElement

        this.revealRequests$ = fromEvent(this.revealButton, 'click')
        this.hideRequests$ = fromEvent(this.counterContainer, 'click')

        this.revealRequests$.subscribe((e) => {
            e.preventDefault()
            e.stopPropagation()
            this.reveal()
        })

        this.setInputAttributes()
        this.completeReveal()
    }

    getInputName() {
        return this.input.name
    }

    // noinspection JSUnusedGlobalSymbols
    getInputValue() {
        return this.input.value
    }

    // ------------------------------------------------------------------------------
    //      Enable / Disable input element
    // ------------------------------------------------------------------------------

    disable() {
        this.input.disabled = true
        return this
    }

    enable() {
        this.input.disabled = false
        return this
    }

    /**
     * Reveals the password for a configured amount of time.
     * @protected
     */
    reveal() {

        this.input.type = 'text'
        this.input.focus()

        show(this.counterContainer)
        hide(this.revealButton)

        this.createCountDownObservable().pipe(takeUntil(this.hideRequests$)).subscribe({
            next: (percentage) => this.setCounterPercentage(percentage),
            complete: () => this.completeReveal(),
        })
    }

    private completeReveal(): this {
        this.input.type = 'password'
        hide(this.counterContainer)
        show(this.revealButton)
        return this
    }

    /**
     * @param {number} percentage
     * @return {PasswordField}
     * @protected
     */
    private setCounterPercentage(percentage: number): this {
        // @ts-ignore
        this.vueInstance.percentage = percentage
        return this
    }

    /**
     * Creates an observable that counts down from 100 to 0 with given interval.
     *
     * @return {Observable}
     * @protected
     */
    createCountDownObservable(ivl = this.options.revealDuration / 100) {
        return interval(ivl).pipe(
            map((n) => 100 - n),
            takeWhile((n) => n >= 0),
        )
    }

    setInputAttributes() {
        this.input.setAttribute('autocorrect', 'off')
        this.input.setAttribute('autocapitalize', 'none')
        this.input.setAttribute('spellcheck', 'false')
    }
}
