import { toNumber } from 'lodash-es'
import { API as noUiSlider } from 'nouislider'
import { connectRange } from 'instantsearch.js/es/connectors'
import { isFiniteNumber } from 'instantsearch.js/es/lib/utils'
import { RangeMax, RangeMin } from 'instantsearch.js/es/connectors/range/connectRange'

import { FacetAttribute } from '../../algolia.types'

/**
 * The options interface for the {@link createSizeRangeWidget} factory function.
 */
export interface CustomSizeRangeOptions {
    slider: noUiSlider
    attribute: FacetAttribute
}

const getRangeMin = (slider: noUiSlider): number => slider.options?.range?.min as number
const getRangeMax = (slider: noUiSlider): number => slider.options?.range?.max as number

/**
 * Creates a custom size-range connector widget attached to the given {@link noUiSlider}.
 */
export function createSizeRangeWidget(
    options: CustomSizeRangeOptions,
    minInput: HTMLInputElement,
    maxInput: HTMLInputElement,
) {
    let minSize: number = getRangeMin(options.slider)
    let maxSize: number = getRangeMax(options.slider)

    const updateSliderRange = (originalMin: number, originalMax: number) => {
        options.slider.updateOptions({
            range: {
                min: originalMin,
                max: originalMax,
            },
        }, false)

        const sliderContainer: HTMLElement = document.querySelector(`#size-${options.attribute}-range`)?.parentElement as HTMLElement
        const hideSlider = originalMin === originalMax

        if (sliderContainer) {
            sliderContainer.classList.toggle('hide', hideSlider)

            if (! hideSlider) {
                let updatedMin = Math.max(originalMin, parseFloat(minInput.value))
                let updatedMax = Math.min(originalMax, parseFloat(maxInput.value))

                if (minInput.value !== updatedMin.toString()) {
                    minInput.value = updatedMin.toString()
                }

                if (maxInput.value !== updatedMax.toString()) {
                    maxInput.value = updatedMax.toString()
                }
            }
        }
    }

    const f = connectRange((renderOptions, isFirstRender) => {

        if (isFirstRender) {
            const inputs: HTMLInputElement[] = [minInput, maxInput]

            inputs.forEach((elem) => {
                elem.addEventListener('change', (e: Event) => {
                    const eventTarget = e.target as HTMLInputElement

                    const elementIsMinInput = elem.id.includes('min-input')
                    const minValue: RangeMin = elementIsMinInput
                        ? toNumber(eventTarget.value)
                        : toNumber(minInput.value)
                    const maxValue: RangeMax = elementIsMinInput
                        ? toNumber(maxInput.value)
                        : toNumber(eventTarget.value)

                    renderOptions.refine([
                        renderOptions.range.min === minValue ? undefined : minValue,
                        renderOptions.range.max === maxValue ? undefined : maxValue,
                    ])
                })
            })

            options.slider.on('change', ([min, max]: [number, number]) => {
                minSize = min
                maxSize = max

                renderOptions.refine([
                    renderOptions.range.min === minSize ? undefined : minSize,
                    renderOptions.range.max === maxSize ? undefined : maxSize,
                ])
            })
        }

        if (! isFirstRender) {
            updateSliderRange(renderOptions.range?.min as number, renderOptions.range?.max as number)
        }

        options.slider.set([
            isFiniteNumber(renderOptions.start[0]) ? renderOptions.start[0] : getRangeMin(options.slider),
            isFiniteNumber(renderOptions.start[1]) ? renderOptions.start[1] : getRangeMax(options.slider),
        ])
    })

    return f({
        ...options,
    })
}
