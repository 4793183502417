import { bugsnagClient } from '@app-bootstrap/bugsnag.bootstrap'
import { AjaxFormFactory, ModalFactory, SwiperFactory } from '@app-factories'
import { HttpClient } from '@app-services'

// ------------------------------------------------------------------------------
// Dependencies
// ------------------------------------------------------------------------------
export const httpClientInjector: HttpClient = new HttpClient()
export const ajaxFormFactoryInjector: AjaxFormFactory = new AjaxFormFactory(httpClientInjector)
export const modalFactoryInjector: ModalFactory = new ModalFactory(ajaxFormFactoryInjector, bugsnagClient)
export const swiperFactoryInjector: SwiperFactory = new SwiperFactory()

