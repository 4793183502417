import { clamp } from 'ramda'
import template from './template'

// -------------------------------------------------------------------
// SVG Vue component
// -------------------------------------------------------------------

export default (elementId: string) => ({

    el: `#${elementId}`,

    template,

    data: {
        id: elementId,
        percentage: 0,
        size: 20,
        strokeWidth: 2,
        strokeColor: '#292929',
        strokeBackgroundColor: '#eaeaea',
        zeroPoint: 'top',
    },

    computed: {

        // -----------------------------------------------------------------
        // SVG attribute values / intermediate calculations
        // -----------------------------------------------------------------

        clampedPercentage() {
            return clamp(0, 100, this.percentage)
        },

        viewBox() {
            return `0 0 ${this.size} ${this.size}`
        },

        fillRadius() {
            return (this.size / 2) - (this.strokeWidth / 2)
        },

        circumference() {
            return 2 * Math.PI * this.fillRadius
        },

        centerOffset() {
            return this.size / 2
        },

        strokeDashoffset() {
            return ((100 - this.clampedPercentage) / 100) * this.circumference
        },

        rotation() {
            if (typeof this.zeroPoint === 'number') {
                return this.zeroPoint
            }

            switch (this.zeroPoint) {
                case 'right':
                    return 0
                case 'bottom':
                    return 90
                case 'left':
                    return 180
                case 'top':
                    return 270
                default:
                    return 270
            }
        },

        // -----------------------------------------------------------------
        // Style declarations
        // -----------------------------------------------------------------

        svgStyle() {
            return {
                transform: `rotate(${this.rotation}deg)`,
            }
        },

        backgroundRingStyle() {
            return {
                strokeWidth: this.strokeWidth,
                stroke: this.strokeBackgroundColor,
            }
        },

        fillRingStyle() {
            return {
                strokeDashoffset: this.strokeDashoffset,
                strokeWidth: this.strokeWidth,
                stroke: this.strokeColor,
            }
        },
    },
})
