/* eslint-disable no-param-reassign */
import { ENV } from '@app-bootstrap/environment.bootstrap'

// ------------------------------------------------------------------------------
//      Logging method calls
// ------------------------------------------------------------------------------

type AnyFn = (...args: any[]) => any

/**
 * Defines the data shape for trace hooks, the argument given to the {@link traced}
 * class-method decorator factory.
 */
export interface TraceHooks<T extends AnyFn = AnyFn> {

    /**
     * (optional) Specify the **before** hook to execute before the decorated method
     * executes. 'taps' the arguments given to the method, and `self` -- the context
     * of the method execution.
     *
     * Note: the arguments are given by reference, so don't write to the data!
     */
    before?(args: Parameters<T>, self: any): void

    /**
     * (optional) Specify the **after** hook to execute after the decorated method
     * executes. 'taps' the return value of the method, the arguments given to the
     * method, and `self` -- the context of the method execution.
     *
     * Note: the arguments are given by reference, so don't write to the data!
     */
    after?(result: ReturnType<T>, args: Parameters<T>, self: any): void
}

const noop = () => undefined

/**
 * Takes an object with `before` and/or `after` (method execution) hooks to execute, and
 * returns a method decorator. This allows to add debugging hooks such as console logs to
 * method input/output information without adding these statements in the method bodies.
 *
 * Traced hooks are only enabled for environments other than production, so that they can
 * remain in the codebase without the risk of exposing information in the live environment.
 */
export function traced({ before = noop, after = noop }: TraceHooks): MethodDecorator {

    return function (target: any, key: string, descriptor: TypedPropertyDescriptor<any>) {

        // Only decorate when not in production!
        if (ENV.isProduction()) return descriptor

        descriptor = descriptor === undefined
            ? Object.getOwnPropertyDescriptor(target, key)!
            : descriptor

        const originalMethod = descriptor.value as AnyFn

        descriptor.value = function (this: typeof target, ...args: any[]) {
            try {
                before(args, this)
            } catch (e) {
                console.warn('Error in TraceHooks ~ before():', e)
            }
            const result = originalMethod.apply(this, args)
            try {
                after(result, args, this)
            } catch (e) {
                console.warn('Error in TraceHooks ~ after():', e)
            }
            return result
        }

        return descriptor
    }
}
