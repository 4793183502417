export const endpoints = {
    cart: {
        index: '/winkelmand',
        counts: '/winkelmand/counts',
        buyable: '/winkelmand/buyable', // REST uri that accepts GET, POST and PUT requests
        notification: '/winkelmand/notification',
        trackingData: '/winkelmand/gtm-tracking-data',
    },
    favorites: {
        index: '/favorieten',
        buyable: '/favorieten/buyable', // REST uri that accepts GET, POST and PUT requests
        trackingData: '/favorieten/gtm-tracking-data',
        addFromWishListToShoppingList: '/favorieten/gtm-tracking-add-from-wishlist-to-shopping-list',
    },
    product: {
        trackingData: '/product/gtm-tracking-data',
    },
    landing: {
        trackingDataView: '/landing/gtm-tracking-data-view',
        trackingDataClick: '/landing/gtm-tracking-data-click',
    },
}
