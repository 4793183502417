import { Environment } from '@app-types/common.types'
import { getMeta } from 'lambda-dom'
import { defaultTo } from 'ramda'

const environment = defaultTo(
    Environment.PRODUCTION,
    getMeta('environment') as Environment | undefined,
)

export const ENV = {
    value: environment,
    stagingUrl: () => getMeta('staging-url') || '',
    productionUrl: () => getMeta('production-url') || '',
    isProduction: () => environment === Environment.PRODUCTION,
    isNotProduction: () => environment !== Environment.PRODUCTION,
    isDevelopment: () => environment === Environment.DEVELOPMENT || environment === Environment.LOCAL,

    /**
     * Runs the given callback function only if the environment is production.
     */
    whenProduction(fn: () => void): void {
        if (ENV.isProduction()) {
            fn()
        }
    },
}
