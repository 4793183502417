import { isNil } from 'ramda'
import { queryAll } from 'lambda-dom'
import type { Widget } from 'instantsearch.js'
import { connectRefinementList } from 'instantsearch.js/es/connectors'
import EventDelegation from '@jjwesterkamp/event-delegation'

import type { DatasetElement } from '@app-types/common.types'
import { CheckboxOperationMode, CheckboxOperator, FacetAttribute, FacetOperationMode } from '../../algolia.types'

type FilterCheckbox = DatasetElement<{
    optionLabel: string,
    optionId: string,
}, HTMLInputElement>

/**
 * Get the appropriate Algolia query operator for the given checkbox operation mode.
 */
const operatorForOperationMode = (mode: CheckboxOperationMode): CheckboxOperator => {
    switch (mode) {
        case FacetOperationMode.ALL_PASS_CHECKBOX:
            return 'and'
        case FacetOperationMode.ANY_PASS_CHECKBOX:
            return 'or'
    }
}

export const createRefinementListWidget = (
    container: HTMLElement,
    attribute: FacetAttribute,
    operationMode: CheckboxOperationMode,
): Widget => {

    const checkboxes = queryAll<FilterCheckbox>('.custom-knob-control--input', container)

    return connectRefinementList((renderOptions, isFirstRender) => {

        if (isFirstRender) {
            EventDelegation
                .within(container)
                .events('change')
                .select<FilterCheckbox>('.custom-knob-control--input')
                .listen((e) => renderOptions.refine(e.delegator.dataset.optionLabel))
        }

        checkboxes.forEach((checkbox) => {
            const matchingItem = renderOptions.items.find((item) => item.label === checkbox.dataset.optionLabel)
            const countLabel = checkbox.parentElement!.querySelector<HTMLElement>('.catalog-filter-control--count')!

            if (isNil(matchingItem)) {
                checkbox.checked = false
                checkbox.disabled = true
                countLabel.innerText = String(0)
            } else {
                checkbox.checked = matchingItem.isRefined
                checkbox.disabled = false
                countLabel.innerText = String(matchingItem.count)
            }
        })
    })({
        attribute,
        operator: operatorForOperationMode(operationMode),
        limit: 100,
    })
}
