export class ProductListToolbarComponent {

    public readonly resultCountContainer: HTMLElement | null
    public readonly initialCountContainer: HTMLElement | null
    public readonly paginationContainer: HTMLElement | null
    public readonly sortingForm: HTMLFormElement | null
    public readonly sortingSelect: HTMLSelectElement | null
    public readonly sortingSelectContainer: HTMLElement | null

    constructor(public readonly container: HTMLElement) {
        this.resultCountContainer = container.querySelector<HTMLElement>('.plt--count')
        this.initialCountContainer = container.querySelector<HTMLElement>('.plt--initial-count')
        this.paginationContainer = container.querySelector<HTMLElement>('.plt--pagination--inner')
        this.sortingForm = container.querySelector<HTMLFormElement>('[data-sorting-form]')
        this.sortingSelect = container.querySelector<HTMLSelectElement>('[data-sorting-select]')
        this.sortingSelectContainer = container.querySelector<HTMLFormElement>('[data-select-container]')
    }
}
