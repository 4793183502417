/**
 * Returns an array containing the integer-division (quotient) and integer-modulus (remainder)
 * of given dividend and divisor.
 *
 * @example (For all divisors >= 1)
 *
 * const [div, mod] = divMod(18, 4)
 * assert(div === 4 && mod === 2)
 */
export const divMod = (dividend: number, divisor: number): [number, number] => [
    div(dividend, divisor),
    mod(dividend, divisor),
]

/**
 * Returns the integer-division (quotient) of given dividend and divisor.
 */
export const div = (dividend: number, divisor: number): number => Math.floor(dividend / divisor)

/**
 * Returns the integer-modulus - or remainder - of given dividend and divisor.
 */
export const mod = (dividend: number, divisor: number): number => dividend % divisor
