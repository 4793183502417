import { ShoppingList } from './ShoppingList'
import { ShoppingListNotificationWrapper } from './ShoppingListNotificationWrapper'
import { ShoppingListBackend } from './ShoppingListBackend'
import { MutationReport } from './MutationReport'

const shoppingListBackend = new ShoppingListBackend({
    endpoints: {
        counts: '/winkelmand/counts',
        buyable: '/winkelmand/buyable', // REST uri that accepts GET, POST and PUT requests
    },

    buyableResponseTransformer(rawResponse) {
        return new MutationReport(JSON.parse(rawResponse))
    },

    countsResponseTransformer(rawResponse) {
        const parsed = JSON.parse(rawResponse)
        return {
            distinctCount: parsed.distinct_count,
            nominalCount: parsed.nominal_count,
        }
    },
})

export const shoppingList = new ShoppingList(shoppingListBackend)

export const notificationWrapper = new ShoppingListNotificationWrapper(
    document.getElementById('shopping-list-notification--inner')!,
)
