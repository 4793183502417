// @ts-ignore
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar'
import { DOMReadyP, touchElementP } from 'lambda-dom'

const makeSideBar = (container: HTMLElement) => new StickySidebar(container, {
    containerSelector: '#sidebar-track',
    innerWrapperSelector: '#summary-sidebar--inner',
    topSpacing: 30,
    bottomSpacing: 30,
    minWidth: 1025,
})

const sideBarP = DOMReadyP()
    .then(() => touchElementP<HTMLElement>('#summary-sidebar'))
    .then(makeSideBar)

export const getSidebar = (): Promise<StickySidebar> => sideBarP

export const updatePosition = (): Promise<void> => getSidebar().then((sidebar) => sidebar.updateSticky())
