/**
 * Change the variant URL from staging to local
 */
export const changeVariantUrlToLocal = (url: string): string => {
    const stagingDomain = 'https://basiclabel.pwstaging.tech/'
    const productionDomain = 'https://basiclabel.nl/'

    if (url.includes(stagingDomain)) {
        return url.replace(stagingDomain, '/')
    } else if (url.includes(productionDomain)) {
        return url.replace(productionDomain, '/')
    } else {
        return url
    }
}
