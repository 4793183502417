import type { FormDataRecord } from '@app-types/form.types'
import { touchAll } from 'lambda-dom'
import { combineLatest, fromEvent } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, skip, startWith } from 'rxjs/operators'
import { formEntries } from '@app-lib/form.lib'
import { equals, mergeRight } from 'ramda'
import QueryString from 'qs'

// ----------------------------------------------s--------------------------------
//      Filter & sorting form change handlers
// ------------------------------------------------------------------------------

// TODO - Fix the selectors
export const useFallbackFormHandling = () => touchAll([
    '#catalog-filter',
    '#catalog-sorting',
], (
    filterForm: HTMLFormElement,
    sortingForm: HTMLFormElement,
) => (
    combineLatest([
        fromEvent(filterForm, 'change').pipe(
            map(() => formEntries(filterForm)),
            startWith(formEntries(filterForm)),
        ),
        fromEvent(sortingForm, 'change').pipe(
            map(() => formEntries(sortingForm)),
            startWith(formEntries(sortingForm)),
        ),
    ]).pipe(
        map(([filterData, sortingData]) => mergeRight(filterData, sortingData)),
        debounceTime(500),
        distinctUntilChanged<FormDataRecord>(equals),
        skip(1),
    ).subscribe((params) => {
        const queryObject = QueryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        // Todo: clear sorting if default
        window.location.href = window.location.pathname + QueryString.stringify(
            mergeRight(queryObject, params),
            {
                addQueryPrefix: true,
                arrayFormat: 'brackets',
                encode: false,
            },
        )
    })
))
