import type { AxiosRequestConfig } from 'axios'
import { axios } from '@app-bootstrap/axios.bootstrap'
import type {
    IMutationReport,
    IShoppingListBackend,
    IShoppingListConfiguration,
    IShoppingListCounts,
} from './shopping-list.types'

export class ShoppingListBackend implements IShoppingListBackend {

    constructor(protected readonly config: IShoppingListConfiguration) {
    }

    public async addBuyable(identifier: string, quantity: number = 1): Promise<IMutationReport> {
        const response = await axios.post<IMutationReport>(this.config.endpoints.buyable, {
            buyable: { identifier, quantity },
        }, this.buyableRequestConfig())

        return response.data
    }

    public async setBuyableQuantity(identifier: string, quantity: number): Promise<IMutationReport> {
        const response = await axios.put<IMutationReport>(this.config.endpoints.buyable, {
            buyable: { identifier, quantity },
        }, this.buyableRequestConfig())

        return response.data
    }

    public async removeBuyable(identifier: string): Promise<IMutationReport> {
        const response = await axios.post<IMutationReport>(this.config.endpoints.buyable, {
            buyable: { identifier },
            _method: 'DELETE',
        }, this.buyableRequestConfig())

        return response.data
    }

    public async getCounts(): Promise<IShoppingListCounts> {
        const response = await axios.get(
            this.config.endpoints.counts,
            this.countsRequestConfig(),
        )

        return response.data
    }

    protected buyableRequestConfig(): AxiosRequestConfig {
        return {
            transformResponse: this.config.buyableResponseTransformer,
        }
    }

    protected countsRequestConfig(): AxiosRequestConfig {
        return {
            transformResponse: this.config.countsResponseTransformer,
        }
    }
}
