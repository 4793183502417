import { API as noUiSliderAPI } from 'nouislider'
import { connectRange } from 'instantsearch.js/es/connectors'
import { isFiniteNumber } from 'instantsearch.js/es/lib/utils'
import { FacetAttribute } from '../../algolia.types'

/**
 * The options interface for the {@link createPriceRangeWidget} factory function.
 */
export interface CustomPriceRangeOptions {
    slider: noUiSliderAPI
    attribute: FacetAttribute
}

const getRangeMin = (slider: noUiSliderAPI): number => slider.options?.range?.min as number
const getRangeMax = (slider: noUiSliderAPI): number => slider.options?.range?.max as number

/**
 * Creates a custom price-range connector widget attached to the given {@link noUiSlider}.
 */
export function createPriceRangeWidget(options: CustomPriceRangeOptions) {
    let minCents: number = getRangeMin(options.slider) * 100
    let maxCents: number = getRangeMax(options.slider) * 100

    const updateSliderRange = (min: number, max: number) => {
        options.slider.enable()
        options.slider.updateOptions({
            range: {
                min: min / 100,
                max: max / 100,
            },
        }, false)

        if (min === max) {
            options.slider.disable()
        }
    }

    const f = connectRange((renderOptions, isFirstRender) => {

        if (isFirstRender) {
            options.slider.on('change', ([min, max]: [number, number]) => {

                minCents = min * 100
                maxCents = max * 100

                renderOptions.refine([
                    renderOptions.range.min === minCents ? undefined : minCents,
                    renderOptions.range.max === maxCents ? undefined : maxCents,
                ])
            })
        }

        updateSliderRange(
            renderOptions.range?.min as number,
            renderOptions.range?.max as number,
        )

        options.slider.set([
            isFiniteNumber(renderOptions.start[0]) ? renderOptions.start[0] / 100 : getRangeMin(options.slider),
            isFiniteNumber(renderOptions.start[1]) ? renderOptions.start[1] / 100 : getRangeMax(options.slider),
        ])
    })

    return f({
        ...options,
    })
}
