import { mergeAll, toPairs } from 'ramda'

const formatFeatureValue = (value: unknown): string => {
    if (value === true) return 'yes'
    if (value === false) return 'no'
    return String(value)
}

const defaultOptions = {
    copyhistory: false,
    directories: false,
    location: false,
    menubar: false,
    resizable: false,
    scrollbars: false,
    status: false,
    toolbar: false,
}

function serializeOptions(options: { [key: string]: any }) {
    return toPairs(options)
        .map(([feature, value]) => `${feature}=${formatFeatureValue(value)}`)
        .join(',')
}

export function openWindow(url: string, name: string, width: number, height: number, options = {}): Window | null {
    const widthCorrected = Math.min(width, screen.width)
    const heightCorrected = Math.min(height, screen.height)

    const windowOptions = mergeAll([defaultOptions, options, {
        width: widthCorrected,
        height: heightCorrected,
        left: (screen.width - widthCorrected) / 2,
        top: (screen.height - heightCorrected) / 2,
    }])

    return window.open(url, name, serializeOptions(windowOptions))
}
