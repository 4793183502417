import { isFunction } from '@app-lib/base.lib'
import { interval, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

/**
 * Trigger a synthetic change event on the given input element.
 *
 * @param {HTMLInputElement} input
 * @return {boolean}
 */
export const triggerChangeEvent = (input: HTMLInputElement) => input.dispatchEvent(new Event('change', {
    bubbles: true,
}))

// ------------------------------------------------------------------------------
//      Fade functions
// ------------------------------------------------------------------------------

const MAXIMUM_STEP_COUNT = 100
const MINIMUM_STEP_INTERVAL = 10

/**
 * @param {number} duration - The duration of the interval in milliseconds.
 */
function createFadeObservable(duration: number): Observable<number> {

    const stepCount = Math.min(
        MAXIMUM_STEP_COUNT,
        Math.ceil(duration / MINIMUM_STEP_INTERVAL),
    )

    return interval(duration / stepCount).pipe(
        map((index) => (index + 1) / stepCount),
        take(stepCount),
    )
}

export function fadeIn(element: HTMLElement, duration: number = 200, display: string | null = null): void {

    element.style.opacity = '0'
    element.style.display = display || ''

    createFadeObservable(duration).subscribe({
        next(progress) {
            element.style.opacity = progress.toFixed(2)
        },
        complete() {
            element.style.opacity = ''
        },
    })
}

export function fadeOut(element: HTMLElement, duration: number = 200): void {
    createFadeObservable(duration).subscribe({
        next(progress) {
            element.style.opacity = (1 - progress).toFixed(2)
        },
        complete() {
            element.style.opacity = ''
            element.style.display = 'none'
        },
    })
}

export function remToPixels(rem: number): number {
    return rem * parseFloat(
        getComputedStyle(
            document.documentElement,
        ).fontSize,
    )
}

/**
 * Queries up the DOM for given `selector`, starting from given `leafElement`.
 * The first element found matching `selector` will be returned.
 * Querying will stop as soon as given `root` is encountered.
 * If no matching element was found, `null` is returned.
 *
 * Based on the {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill MDN `closest` polyfill}
 *
 * @param leaf     The innermost element in the DOM tree to start searching from.
 * @param selector The selector (CSS style) to match ancestor elements with.
 * @param root     The element that acts as a scope for the query.
 */
export function closestWithin(leaf: HTMLElement, selector: string, root: Node): HTMLElement | null {

    if (isFunction(leaf.closest)) {
        const closest = leaf.closest<HTMLElement>(selector)

        if (closest === null || ! root.contains(closest)) {
            return null
        }

        return closest
    }

    if (! root.contains(leaf)) {
        return null
    }

    let current: HTMLElement | null = leaf

    do {
        if (matches(current, selector)) {
            return current
        }

        current = current.parentElement
    } while (
        current !== null
        && current !== root
        && current.nodeType === Node.ELEMENT_NODE
    )

    return null
}

/**
 * An interface extension for `Element` based on the
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/matches MDN `matches` polyfill}
 * used for cross-browser compatible CSS selector matching.
 */
interface XBrowserElement extends Element {
    document?: Document
    matchesSelector?(selectors: string): boolean
    mozMatchesSelector?(selectors: string): boolean
    msMatchesSelector?(selectors: string): boolean
    oMatchesSelector?(selectors: string): boolean
}

/**
 * Tells whether given element matches a given CSS selector.
 *
 * @param element
 * @param selector
 */
export function matches(element: XBrowserElement, selector: string): boolean {
    if (! (element instanceof Element)) {
        throw new Error('cannot match a non-element against a selector')
    }

    if (isFunction(element.matches)) return element.matches(selector)
    if (isFunction(element.matchesSelector)) return element.matchesSelector(selector)
    if (isFunction(element.mozMatchesSelector)) return element.mozMatchesSelector(selector)
    if (isFunction(element.msMatchesSelector)) return element.msMatchesSelector(selector)
    if (isFunction(element.oMatchesSelector)) return element.oMatchesSelector(selector)
    /* istanbul ignore else */
    if (isFunction(element.webkitMatchesSelector)) return element.webkitMatchesSelector(selector)

    /* istanbul ignore next */
    {
        const m = (element.document || element.ownerDocument).querySelectorAll(selector)
        let i = m.length

        // eslint-disable-next-line no-empty
        while (--i >= 0 && m.item(i) !== element) {
        }

        return i > -1
    }
}
