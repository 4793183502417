import animateScrollTo, { IOptions } from 'animated-scroll-to'
import { includes, isNil, mergeRight, not, without } from 'ramda'

// ------------------------------------------------------------------------------
//      Scroll blocking
// ------------------------------------------------------------------------------
export const ScrollBlockers = (() => {

    let names: string[] = []

    return Object.freeze({

        has(name: string): boolean {
            return includes(name, names)
        },

        /**
         * Adds given scroll-blocker to the list of blockers, if it's not already
         * on it.
         * Returned boolean indicates whether the set transitioned from the empty state
         */
        add(name: string): boolean {
            if (this.has(name)) return false
            const names0 = names
            names = [name, ...names]
            this.apply()
            return names0.length === 0
        },

        /**
         * Adds given scroll-blocker to the list of blockers, if it's not already
         * on it.
         * Returned boolean indicates whether the set transitioned to the empty state
         */
        remove(name: string): boolean {
            if (! this.has(name)) return false
            const names0 = names
            names = without([name], names0)
            this.apply()
            return names.length === 0
        },

        toggle(name: string, force?: boolean): boolean {
            if (force ?? not(this.has(name))) {
                return this.add(name)
            }

            return this.remove(name)
        },

        count(): number {
            return names.length
        },

        reset(): void {
            names = []
        },

        isEmpty(): boolean {
            return names.length === 0
        },

        apply(): void {
            document.body.style.overflow = this.isEmpty() ? '' : 'hidden'
        },
    })
})()

// ------------------------------------------------------------------------------
//      Library
// ------------------------------------------------------------------------------

/**
 * Smooth scroll to a given element. Returns a promise that resolves `false` if
 * the scrolling function could not complete, eg. because of new UI scroll events.
 * Resolves `true` otherwise.
 */
export const scrollToElement = (el: HTMLElement, opts: IOptions = {}): Promise<boolean> => (
    animateScrollTo(el, mergeRight<IOptions, IOptions>(opts, {
        maxDuration: 1500,
        easing: (x) => (x < 0.5 ? 16 * x * x * x * x * x : 1 - window.Math.pow(-2 * x + 2, 5) / 2),
    }))
)

/**
 * Smooth scroll to the element by given `id`. Returns a promise that resolves `false`
 * if such an element does not exist, or if the scrolling function reports failure.
 * Resolves `true` otherwise.
 */
export const scrollToId = (id: string, opts?: IOptions): Promise<boolean> => {

    const element = document.getElementById(id)

    return isNil(element)
        ? Promise.resolve(false)
        : scrollToElement(element, opts)
}
